<template>
  <div>
    <div
      :style="{ 'margin-left': `${depth * 20}px` }"
      class="node"
      @click="nodeClicked"
    >
      <span v-if="hasChildren" class="type">
        <!-- For Browse Data Icons -->
        <span v-if="treeType === 'browseData'">
          <i v-if="expanded" class="fa fa-angle-down" aria-hidden="true" />
          <i v-else class="fa fa-angle-right" aria-hidden="true" />&nbsp;

          <i
            v-if="depth === 0"
            class="fa fa-snowflake-o btn-tableIcons"
            aria-hidden="true"
          />
          <i
            v-else-if="depth === 1"
            class="fa fa-database btn-tableIcons"
            aria-hidden="true"
          />
          <i v-else class="fa fa-th btn-tableIcons" aria-hidden="true" />
          <a
            v-if="node.clickable"
            :style="selectedTreeItem === node.link ? 'font-weight: bold;' : ''"
          >&nbsp;{{ node.name }}</a>
          <span v-else>&nbsp;{{ node.name }}</span>
        </span>

        <!-- For Looker Grapher Tree -->
        <span v-if="treeType === 'grapherTree'">
          <span :class="`color${depth}`">
            <i v-if="expanded" class="fa fa-minus-square" aria-hidden="true" />
            <i v-else class="fa fa-plus-square" aria-hidden="true" />&nbsp;
          </span>
          <span>&nbsp;{{ node.name }}</span>
        </span>
      </span>
      <span v-else class="type">
        <!-- For Browse Data Icons -->
        <span>
          <i class="fa fa-table btn-tableIcons" aria-hidden="true" />
          <a
            v-if="node.clickable"
            :style="selectedTreeItem === node.link ? 'font-weight: bold;' : ''"
          >
            &nbsp;{{ node.name }}
          </a>
          <span v-else>&nbsp;{{ node.name }}</span>
        </span>

        <!-- For Looker Grapher Tree -->
        <span v-if="treeType === 'grapherTree'">
          <span :class="node.type === `view_name` ? `color3` : `color4`">
            <i class="fa fa-stop-circle" aria-hidden="true" />
          </span>
          <span>&nbsp;{{ node.name }}</span>
        </span>
      </span>
    </div>
    <div v-if="expanded">
      <TreeBrowser
        v-for="child in node.children"
        :key="child.name"
        :node="child"
        :depth="depth + 1"
        :treeType="treeType"
        class="children"
        @onClick="(node) => $emit('on-click', node)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TreeBrowser',
  props: {
    node: {
      type: Object,
      default() {}
    },
    depth: {
      type: Number,
      default: 0
    },
    treeType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    ...mapState({
      selectedTreeItem: state => state.search.selectedTreeItem
    }),
    hasChildren() {
      return this.node.children;
    },
    isNodeSelected() {
      if (this.selectedTreeItem) {
        return this.selectedTreeItem.startsWith(this.node.link);
      }
      return false;
    }
  },
  created() {
    this.expanded = (
      this.depth === 0
      || (this.selectedTreeItem
        && this.selectedTreeItem.startsWith(this.node.link))
    );
  },
  methods: {
    ...mapActions({
      setSelectedTreeItem: 'search/setSelectedTreeItem'
    }),
    nodeClicked() {
      this.expanded = !this.expanded;
      if (this.node.clickable) {
        this.setSelectedTreeItem({
          selectedTreeItem: this.node.link
        });
      }
    }
  }
};
</script>

<style scoped>
.node {
  text-align: left;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.type {
  margin-right: 10px;
  cursor: pointer;
}
.color0 {
  color: #4b4b4b;
}
.color1 {
  color: rgb(31, 119, 180);
}
.color2 {
  color: rgb(255, 127, 14);
}
.color3 {
  color: rgb(44, 160, 44);
}
.color4 {
  color: rgb(214, 39, 40);
}
</style>
