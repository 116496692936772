<template>
  <div v-if="schemaInfo" class="row">
    <div class="col-sm-8" style="padding-left: 0px">
      <div class="card">
        <div class="card-block">
          <Editable
            v-if="!descriptionUpdating"
            :description="
              schemaInfo.description === '\n\n' ||
                schemaInfo.description === '\n'
                ? ''
                : schemaInfo.description
            "
            :entityId="schemaInfo.key"
            entityType="Schema"
            :disabled="!isUserSteward"
            @update:description="schemaDescriptionChanged"
          />
          <div v-if="descriptionUpdating" align="left">
            <p style="color: #00738e; font-size: 1.5em; margin-bottom: 0px">
              Description
            </p>
            <hr style="margin-top: 5px; width: 100%" />
            <img src="spinner.svg" width="25" alt="loading" />
          </div>
        </div>
        <error-popup
          v-if="!descriptionUpdating && errorMessage"
          errorType="alert-danger"
          :message="errorMessage"
          class="card-block"
          style="padding: 0px 0px 10px 16px"
        />
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card">
        <div class="card-block">
          <Domain objectType="schema" :objectKey="schemaInfo.key" :domainId="domainId" :disabled="!isUserSteward" :cluster="this.schemaInfo.cluster" />
          <br />
          <OverviewFields
            heading="Data Owner"
            :keyValue="schemaInfo.key"
            :isUserSteward="isUserSteward"
            entity="squad_email"
            objectType="schema"
            tooltip="The contact information (team email) to get in touch with the data owner. Only stewards are allowed to edit."
          />

          <Stewards :keyValue="schemaInfo.key" objectType="schema" />
          <br />

          <OverviewFields
            heading="Slack Channel"
            :keyValue="schemaInfo.key"
            :isUserSteward="isUserSteward"
            entity="slack_channel"
            objectType="schema"
            :objectCluster="schemaInfo.cluster"
            tooltip="The monitored support channel in Slack to get in touch with the data owner. Only stewards are allowed to edit."
          />

          <Tags :keyValue="schemaInfo.key" :objectType="'schema'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Editable from '../shared/overview/Editable';
import Stewards from '../shared/overview/Stewards';
import Domain from '../shared/overview/Domain.vue';
import OverviewFields from '../shared/overview/OverviewFields';
import Tags from '../shared/overview/Tags';

import ErrorPopup from '../ErrorPopup.vue';

export default {
  name: 'OverviewSection',
  inject: ['userManager', 'metadataPlatformClient'],
  components: {
    Editable,
    Tags,
    Stewards,
    OverviewFields,
    ErrorPopup,
    Domain
  },
  data() {
    return {
      descriptionUpdating: false,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      schemaInfo: state => state.schemas.schemaInfo
    }),
    schemaKeyDecoded() {
      return encodeURIComponent(this.schemaInfo.key);
    },
    isUserSteward() {
      const userEmail = this.userManager.userData.app_metadata.canonical_id.toLowerCase();
      return !!this.schemaInfo.owners.find(
        userData => userData.email === userEmail
      );
    },
    domainId() {
      const domainPD = (this.schemaInfo.programmatic_descriptions || [])
        .find(b => b.source === 'domain_id') || {};
      return domainPD.text;
    }
  },
  methods: {
    ...mapActions({
      putDescriptionn: 'schemas/putDescriptionn'
    }),
    async schemaDescriptionChanged(event) {
      this.descriptionUpdating = true;
      this.errorMessage = null;
      try {
        await this.putDescriptionn({
          platformClient: this.metadataPlatformClient,
          keyValue: this.schemaKeyDecoded,
          description: event.description === '' ? '\n\n' : event.description
        });
        this.$ga.event('schema', 'edit', unescape(this.schemaKeyDecoded), 1);
      } catch (e) {
        this.errorMessage
          = e.message
          || e.data.message
          || e.data.title
          || (e.status && `Error occurred with status code ${e.status}`)
          || 'Unknown error occurred.';
        console.log(e);
      }
      this.descriptionUpdating = false;
    }
  }
};
</script>
