
export function convertItemKeyToWrapperObjectKey(itemKey, objectType) {
  let objectKey = '';
  const parts = itemKey.split('.');
  const cluster = `${parts[0]}.${parts[1]}`;
  const database = parts[2];
  const schema = parts[3];
  const objectName = parts[4];
  if (objectType === 'dataset') {
    objectKey = `${cluster}:${database}.${schema}.${objectName}`;
  } else if (objectType === 'schema') {
    objectKey = `${cluster}:${database}.${schema}`;
  }
  return objectKey;
}
