<template>
  <div>
    <div>
      <span v-if="['Table','Schema','View'].includes(entityType) " class="pull-right customlink" @click="HistoryOn=true">
        <Tooltip direction="top" contents="History" class="inline">
          <IconSynchronizeArrowClock size="lg" />
        </Tooltip>
      </span>
      <EditHistoryTable v-if="HistoryOn" :entityId="entityId" :entityType="entityType" @closeModal="HistoryOn = false" />
    </div>
    <div>
      <button v-if="!isEditing && userManager.userData.app_metadata.canonical_id && !this.isObjectReadOnly" v-show="!disabled" class="btn-icon transparent pull-right" style="padding-right: 11px;" @click="startEditing">
        <Tooltip direction="top" contents="Edit">
          <i class="fa fa-pencil text-primary" style="padding: 0" />
        </Tooltip>
      </button>
      <p style="color: #00738e; font-size: 1.5em; margin-bottom: 0px" class="inline pull-left">
        Description
      </p>
      <Tooltip
        direction="top"
        :contents="'Description of the ' + entityType + '. Only stewards are allowed to edit'"
        style="padding-left: 1rem"
      >
        <IconInformationCircle size="md" weight="fill" class="text-primary" />
      </Tooltip>
      <br />
      <hr style="margin-top: 0px; width: 100%;">
    </div>
    <span v-if="(disabled || this.isObjectReadOnly) && !currentDescription" class="text-muted">
      {{ entityType }} description is not defined
    </span>
    <div v-if="!isEditing">
      <a v-if="!currentDescription && userManager.userData.app_metadata.canonical_id && !this.isObjectReadOnly" v-show="!disabled" class="customlink" @click="startEditing">+ Add Description</a>
      <!-- eslint-disable -->
      <div style="word-wrap: break-word;" v-if="currentDescription" v-html="compiledMarkdown" />
      <!-- eslint-enable -->
    </div>
    <div v-else class="editBox">
      <vue-simplemde ref="markdownEditor" v-model="textToEdit" class="full-description" />
      <Button size="md" variant="primary" class="pull-right" @onClick="updateClick()">
        Update
      </Button>
      <Button size="md" variant="default" class="inline pull-right" @onClick="isEditing=false;">
        Cancel
      </Button>
    </div>
    <ConfirmBox
        v-if="showWarning"
        :title="`Remove Description`"
        :isWarning=true
        :message="`Your dataset <b>won't be stable</b> anymore. Are you sure you want to remove <b>Description</b>?`"
        @confirmOperation="
          () => {
            notifyChange();
            this.$emit('status:downgrade');
          }
        "
        @closeModal="showWarning = false"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueSimplemde from 'vue-simplemde';
import { Tooltip, Button } from '@cimpress/react-components';
import marked from 'marked';
import { IconSynchronizeArrowClock, IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import EditHistoryTable from '../../EditHistoryTable';
import ConfirmBox from '../../ConfirmBox';

export default {
  name: 'Editable',
  components: {
    ConfirmBox,
    VueSimplemde,
    IconSynchronizeArrowClock,
    IconInformationCircle,
    EditHistoryTable,
    Tooltip,
    Button
  },
  inject: ['userManager'],
  props: {
    disabled: {
      type: Boolean,
      default: () => true
    },
    description: {
      type: String,
      default: () => ''
    },
    entityId: {
      type: String,
      default: () => ''
    },
    entityType: {
      type: String,
      default: () => ''
    },
    isStable: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      isEditing: false,
      currentDescription: this.description,
      textToEdit: '',
      HistoryOn: false,
      showWarning: false
    };
  },
  computed: {
    ...mapState({
      missingStabilityCriteria: state => state.tables.missingStabilityCriteria,
      isObjectReadOnly: state => state.tables.isObjectReadOnly
    }),
    compiledMarkdown() {
      return marked(this.currentDescription, { sanitize: true });
    }
  },
  watch: {
    description() {
      this.currentDescription = this.description;
    }
  },
  methods: {
    startEditing() {
      this.textToEdit = this.currentDescription;
      this.isEditing = true;
    },
    updateClick() {
      if (!this.textToEdit.trim() && this.isStable && !this.missingStabilityCriteria.length) {
        this.showWarning = true;
      } else {
        this.notifyChange();
      }
    },
    notifyChange() {
      this.isEditing = false;
      if (this.textToEdit !== this.currentDescription) {
        this.$emit('update:description', { description: this.textToEdit });
        this.currentDescription = this.textToEdit;
      }
    }
  }
};
</script>
<style>
  @import '~simplemde/dist/simplemde.min.css';
.full-description{
  max-height: 500px;
  overflow-y: auto;
}
</style>
