import { render, staticRenderFns } from "./ConfirmBox.vue?vue&type=template&id=c30a1172&scoped=true&"
import script from "./ConfirmBox.vue?vue&type=script&lang=js&"
export * from "./ConfirmBox.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmBox.vue?vue&type=style&index=0&id=c30a1172&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c30a1172",
  null
  
)

export default component.exports