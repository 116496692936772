<template>
  <div style="overflow-x: auto;">
    <img v-if="!topUsers && !errorLoadingTopUsers" src="../../../assets/spinner.svg" width="25px">
    <error-popup v-if="errorLoadingTopUsers"
                 errorType="alert-danger"
                 message="Error retrieving Top Users"
                 tooltipMessage="Try again or Contact PandaSquad@cimpress.com"
    />
    <div v-for="(reader, index) in topUsers" :key="reader.id" style="margin-bottom: 5px; font-size: 12px; letter-spacing: -.02rem;">
      <span v-if="index < 5 && reader['frequent_users.user_name']">
        <button class="owner-btn btn btn-primary">
          {{ reader['frequent_users.user_name'].charAt(0) }}
        </button>
        {{ reader['frequent_users.user_name'] }}
        {{ '&nbsp;(' +reader['frequent_users.query_count']+ '&nbsp;queries)' }}
      </span>
    </div>
    <div v-if="topUsers && topUsers.length<=0">
      <Tooltip direction="top" contents="No data found for this Data object. Please try again or contact PandaSquad@cimpress.com" class="inline">
        <i class="fa fa-exclamation-triangle" style="color:#ffa23f;" title="Empty List Found" />
      </Tooltip> List Empty
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import ErrorPopup from '../../ErrorPopup.vue';
export default {
  inject: ['platformClient'],
  components: {
    Tooltip,
    ErrorPopup
  },
  computed: {
    ...mapState({
      topUsers: state => state.nala.topUsers,
      errorLoadingTopUsers: state => state.nala.errorLoadingTopUsers
    })
  }
};
</script>
