<template>
  <div style="height: 100%">
    <div v-if="processingMessage" align="center">
      <img src="spinner.svg" alt="loading" />
      <span>{{ processingMessage }}</span>
    </div>
    <div v-else-if="errorDashboard">
      <robot-image />
      {{ errorDashboard }}
    </div>
    <iframe
      v-show="!processingMessage && embedUrl"
      :id="configurationId"
      :src="embedUrl"
      :height="`${dashboardHeight}px`"
      width="100%"
      class="iframes"
    />
  </div>
</template>
<script>
import RobotImage from './RobotImage';
import { getDataObjectSignedUrl } from '../../clients/nala';

export default {
  name: 'LookerDashboard',
  inject: ['platformClient'],
  components: {
    RobotImage
  },
  props: {
    itemKey: {
      type: String,
      required: true
    },
    configurationId: {
      type: String,
      required: true
    },
    objectType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errorDashboard: null,
      processingMessage: null,
      embedUrl: null,
      dashboardHeight: 20
    };
  },
  computed: {
    dashboardKey() {
      return `${this.itemKey}-${this.configurationId}`;
    }
  },
  mounted() {
    window.addEventListener('message', this.eventReceived);
  },
  async created() {
    this.processingMessage = `Loading ${this.objectType} url...`;
    this.embedUrl = null;
    this.errorDashboard = null;

    const parts = this.itemKey.split('.');
    const cluster = `${parts[0]}.${parts[1]}`;
    const database = parts[2];
    const schema = parts[3];
    const objectName = parts[4];
    try {
      this.embedUrl = await getDataObjectSignedUrl({
        platformClient: this.platformClient,
        configurationId: this.configurationId,
        userAttributeValues: {
          account_read_details: ['*']
        },
        account: cluster.replace('.eu-west-1', ''),
        database,
        schema,
        objectName
      });
      this.processingMessage = `Loading ${this.objectType} dashboard...`;
    } catch (error) {
      this.errorDashboard = error;
      this.processingMessage = null;
    }
  },
  destroyed() {
    window.removeEventListener('message', this.eventReceived);
  },
  methods: {
    eventReceived(event) {
      if (
        event.source
        === document.getElementById(this.configurationId)
          .contentWindow
      ) {
        if (event.origin === 'https://embed.looker.cimpress.io') {
          const data
            = typeof event.data === 'string'
              ? JSON.parse(event.data)
              : event.data;
          if (data.type === 'page:properties:changed') {
            this.dashboardHeight = data.height;
          } else if (data.type === 'dashboard:loaded') {
            this.processingMessage = null;
          } else if (data.type === 'status' && data.status === 'error') {
            this.errorDashboard
              = 'Error loading the dashboard, try relading the page.';
            this.processingMessage = null;
          }
        }
      }
    }
  }
};
</script>
