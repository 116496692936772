<template>
  <div class="row">
    <div class="col-sm-3">
      <div style="padding-top: 25px; padding-left: 25px">
        <BrowseData />
      </div>
    </div>
    <div class="col-sm-9" style="padding-top: 25px;">
      <div
        v-if="selectedTreeItem"
        class="card tab-card"
        style="margin: 0px 20px 20px 20px; padding: 0px;"
      >
        <div
          class="card-header card-tabs"
          style="background-color: white; border-bottom: 1px solid #edf3f8;"
        >
          <span
            class="inline card-block"
            style="background-color: #eff3f5; cursor: pointer;"
            @click="closeObjectTab"
          >
            <i class="fa fa-chevron-left" />&nbsp;
            <span>Search</span>
          </span>
          <div v-if="!this.cluster" align="center">
            <img src="spinner.svg" alt="loading" />
          </div>
          <div v-else class="inline">
            <span class="customlink">
              <a
                :href="`https://${this.cluster}.snowflakecomputing.com`"
                target="_blank"
              >
                <Tooltip direction="top" contents="PDW" class="inline">
                  <i class="fa fa-snowflake-o" />&nbsp;
                  <span class="text-mid">{{
                    this.cluster.replace('.eu-west-1', '')
                  }}</span>
                </Tooltip>
              </a>
            </span>
            <span class="right-arrow">
              <i class="fa fa fa-angle-right" aria-hidden="true" />&nbsp;
            </span>
            <span class="customlink">
              <a>
                <Tooltip direction="top" contents="Database" class="inline">
                  <i class="fa fa-database btn-tableIcons" />&nbsp;
                  <span class="text-mid">{{ this.database }} </span>
                </Tooltip>
              </a>
            </span>
            <span v-if="objectType === 'table'" class="right-arrow">
              <i class="fa fa fa-angle-right" aria-hidden="true" />&nbsp;
            </span>
            <span v-if="objectType === 'table'" class="customlink">
              <a
                @click="
                  setSelectedTreeItem({
                    selectedTreeItem: `${cluster}.${database}.${schema}`
                  })
                "
              >
                <Tooltip direction="top" contents="Schema" class="inline">
                  <i class="fa fa-th btn-tableIcons" />&nbsp;
                  <span class="text-mid">{{ this.schema }} </span>
                </Tooltip>
              </a>
            </span>
          </div>
        </div>

        <Schema v-if="objectType === 'schema'" :itemKey="selectedTreeItem" />
        <Table v-if="objectType === 'table'" :itemKey="selectedTreeItem" />
      </div>

      <div v-show="!selectedTreeItem" style="padding: 0px 20px 20px 20px">
        <Search />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import Search from '@/components/Search/Search';
import BrowseData from '@/components/Search/BrowseData.vue';
import Table from '@/components/TableInfo/Table';
import Schema from '@/components/SchemaInfo/Schema';
import urlEncodeSearchInput from '@/utils/urlEncodeSearchInput';

export default {
  name: 'Home',
  inject: ['userManager', 'platformClient'],
  components: {
    Tooltip,
    Search,
    BrowseData,
    Schema,
    Table
  },
  data() {
    return {
      cluster: '',
      database: '',
      schema: '',
      datasetName: ''
    };
  },
  computed: {
    ...mapState({
      selectedTreeItem: state => state.search.selectedTreeItem,
      searchInput: state => state.search.searchInput,
      currentPage: state => state.search.currentPage
    }),
    objectType() {
      if (this.selectedTreeItem) {
        const parts = this.selectedTreeItem.split('.').length;
        return parts >= 5 ? 'table' : 'schema';
      }
      return null;
    }
  },
  watch: {
    selectedTreeItem() {
      if (this.$route.query.selectedTreeItem !== this.selectedTreeItem) {
        this.$router.push({
          query: { selectedTreeItem: this.selectedTreeItem }
        });
      }
      if (!this.selectedTreeItem) {
        return;
      }
      const parts = this.selectedTreeItem.split('.');
      this.cluster = `${parts[0]}.${parts[1]}`;
      this.database = parts[2];
      this.schema = parts[3];
      if (parts.length === 5) {
        this.datasetName = parts[4];
      }
    },
    $route(to) {
      this.setSelectedTreeItem({
        selectedTreeItem: to.query.selectedTreeItem
      });
    }
  },
  created() {
    Promise.all([
      this.getBrowseData({
        platformClient: this.platformClient,
        email: this.userManager.userData.app_metadata.canonical_id,
        userAccountId: this.userManager.userData.app_metadata.account.id
      }),
      this.getAllTags({ platformClient: this.platformClient }),
      this.getAllBookmarks({
        platformClient: this.platformClient,
        userEmail: this.userManager.userData.app_metadata.canonical_id
      })
    ]);
    this.setSelectedTreeItem({
      selectedTreeItem: this.$route.query.selectedTreeItem
    });

    if (!this.selectedTreeItem) {
      return;
    }
    const parts = this.selectedTreeItem.split('.');
    this.cluster = `${parts[0]}.${parts[1]}`;
    this.database = parts[2];
    this.schema = parts[3];
    if (parts.length === 5) {
      this.datasetName = parts[4];
    }
  },
  methods: {
    ...mapActions({
      setSelectedTreeItem: 'search/setSelectedTreeItem',
      getSearchInputQuery: 'search/getSearchInputQuery',
      getAllTags: 'tags/getAllTags',
      getBrowseData: 'search/getBrowseData',
      getAllBookmarks: 'bookmark/getAllBookmarks'
    }),
    closeObjectTab() {
      this.$router.push({
        query: {
          searchInput: urlEncodeSearchInput(this.searchInput),
          page: this.currentPage
        }
      });
    }
  }
};
</script>
<style scoped>
.search-item {
  text-align: left !important;
}
</style>
