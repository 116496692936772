var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.schema)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('small',{staticStyle:{"color":"grey"}},[_vm._v(" Schema ")]),_c('h2',{staticStyle:{"word-break":"break-word","margin-top":"0px"}},[_vm._v(" "+_vm._s(_vm.schema.schema)+" ")]),_c('small',{staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.objectName)+"  "),_c('Tooltip',{staticClass:"inline",attrs:{"direction":"top","contents":"Copy schema full name"}},[_c('a',[_c('i',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.objectName),expression:"objectName",arg:"copy"}],staticClass:"fa fa-copy customlink"})])])],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticStyle:{"float":"right"}},[_c('Tooltip',{attrs:{"direction":"top","contents":"Download schema metadata"}},[_c('div',{staticStyle:{"font-size":"2em"}},[_c('DownloadData',{attrs:{"fileName":((_vm.schema.schema) + "-metadata"),"iconSize":"fa-sm","downloadTitle":"","fetchData":function () { return ({
                database: _vm.schema.database,
                schema: _vm.schema.schema,
                cluster: _vm.schema.cluster,
                description: _vm.schema.description,
                stewardEmails: _vm.schema.owners.map(function (o) { return o.email; }),
                dataOwners: (_vm.schema.programmatic_descriptions.find(function (p) { return p.source === 'squad_email'; }) || {}).text,
                slackChannels: (_vm.schema.programmatic_descriptions.find(function (p) { return p.source === 'slack_channel'; }) || {}).text,
                domain_id: (_vm.schema.programmatic_descriptions.find(function (p) { return p.source === 'domain_id'; }) || {}).text,
                tags: _vm.schema.tags.map(function (o) { return o.tag_name; }),
              }); }}})],1)])],1)])]),_c('hr')]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }