<template>
  <div @keyup.left="$refs.btnYes.focus();" @keyup.right="$refs.btnNo.focus();">
    <div class="modal fade in" tabindex="-1" style="visibility: visible; display: block; padding-left: 0px;">
      <div class="modal-dialog foobar" style="z-index: 1050; width: 35%;">
        <div class="modal-content modal-info">
          <div class="modal-header">
            <div
              v-if="isWarning"
              class="stabilityWarning inline"
              style="margin: 8px"
              @click="(event) => event.preventDefault()"
            >
              <i style="font-size: 2rem !important" class="fa fa-exclamation-triangle fa-lg" />
            </div>
            <div class="modal-title">
              {{ title }}
            </div>
            <button class="close" @click="$emit('closeModal')">
              x
            </button>
          </div>
          <br/>
          <!-- eslint-disable -->
          <div class="modal-body" style="padding-top: 0px" v-html="message" />
          <!-- eslint-enable -->
          <div v-if="showButtons" class="modal-footer">
            <button ref="btnYes" class="confirm-btn btn btn-primary" @click="$emit('confirmOperation');">
              {{ confirmBtnTitle }}
            </button>
            <button v-if="isPiiAction" ref="btnNo" class="btn btn-danger" @click="$emit('unmarkOperation');">
              Dismiss
            </button>
            <button v-else ref="btnNo" class="close-btn btn btn-danger" @click="$emit('closeModal')">
              No
            </button>
          </div>
        </div>
      </div>
      <div class="close-btn modal-backdrop fade in" @click="$emit('closeModal')" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmBox',
  props: {
    title: {
      type: String,
      default: () => '',
      required: true
    },
    message: {
      type: String,
      default: () => '',
      required: true
    },
    confirmBtnTitle: {
      type: String,
      default: () => 'Yes',
      required: false
    },
    isPiiAction: {
      type: Boolean,
      default: false,
      required: false
    },
    showButtons: {
      type: Boolean,
      default: true,
      required: false
    },
    isWarning: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  mounted() {
    this.$refs.btnYes.focus();
  }
};
</script>

<style scoped>
.col-type{
  color: #3a97d3
}
button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #35ad9688;
}
.stabilityWarning {
  color: #ffae42;
}
</style>
