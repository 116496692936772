<template>
  <div v-if="schema">
    <div class="row">
      <div class="col-md-8">
        <small style="color: grey">
          Schema
        </small>
        <h2 style="word-break: break-word; margin-top: 0px">
          {{ schema.schema }}
        </h2>
        <small style="word-break: break-all">
          {{ objectName }}&nbsp;
          <Tooltip
            direction="top"
            contents="Copy schema full name"
            class="inline"
          >
            <a>
              <i
                v-clipboard:copy="objectName"
                class="fa fa-copy customlink"
              />
            </a>
          </Tooltip>
        </small>
      </div>

      <div class="col-md-4">
        <div style="float: right;">
          <Tooltip direction="top" contents="Download schema metadata">
            <div style="font-size: 2em">
              <DownloadData
                :fileName="`${schema.schema}-metadata`"
                iconSize="fa-sm"
                downloadTitle=""
                :fetchData=" () => ({
                  database: schema.database,
                  schema: schema.schema,
                  cluster: schema.cluster,
                  description: schema.description,
                  stewardEmails: schema.owners.map((o) => o.email),
                  dataOwners: (schema.programmatic_descriptions.find(p => p.source === 'squad_email') || {}).text,
                  slackChannels: (schema.programmatic_descriptions.find(p => p.source === 'slack_channel') || {}).text,
                  domain_id: (schema.programmatic_descriptions.find(p => p.source === 'domain_id') || {}).text,
                  tags: schema.tags.map((o) => o.tag_name),
                })
                "
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import DownloadData from '../DownloadData';
Vue.use(VueClipboard);
export default {
  name: 'SchemaHeader',

  inject: ['platformClient', 'userManager'],

  components: {
    Tooltip,
    DownloadData
  },
  props: {
    keyValue: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      loading: false,
      tableStatusHistoryOn: false
    };
  },
  computed: {
    ...mapState({
      schema: state => state.schemas.schemaInfo,
      customFields: state => state.schemas.customFields
    }),
    isUserSteward() {
      const userEmail = this.userManager.userData.app_metadata.canonical_id.toLowerCase();
      return !!this.schemaInfo.owners.find(owner => owner.email.toLowerCase() === userEmail);
    },
    objectName() {
      return `"${this.schema.database.toUpperCase()}"."${this.schema.schema.toUpperCase()}""`;
    }
  },
  methods: {
    ...mapActions({
      setSelectedTreeItem: 'search/setSelectedTreeItem'
    })
  }
};
</script>
<style scoped>
.right-arrow {
  color: #999;
  font-size: 1.4em;
}
.text-mid {
  vertical-align: text-top;
}
.loading {
  display: inline-block;
}
</style>
