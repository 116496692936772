<template>
  <div class="card">
    <div style="padding-left: 20px; padding-top: 20px">
      <h5>
        Browse your data
        <Tooltip direction="top"
                 contents="For adding/deleting a database/schema in data discovery reach out at #data-domain-support."
                 class="inline"
        >
          <IconInformationCircle size="md" weight="fill" class="text-primary" />
        </Tooltip>
      </h5>
      <p class="text-muted" style="padding-right: 30px; font-size: 13px;">
        5 minutes of sync delay between snowflake and Data Discovery is expected!
      </p>
      <hr />
    </div>
    <div class="card-block" :style="'height: 75vh; overflow-y: scroll;'">
      <div v-if="loadingBrowseData" align="center">
        <img src="spinner.svg" alt="loading" /><br />
        Loading Data Tree...
      </div>
      <div v-else>
        <TreeBrowser
          v-for="pdw in browse"
          :key="pdw.name"
          :node="pdw"
          treeType="browseData"
        />
      </div>
      <error-popup
        v-if="browseDataError"
        errorType="alert-danger-dismissible"
        :message="browseDataError"
        :html="`${browseDataError}.<br>Try refreshing or contact <a href='mailto:PandaSquad@cimpress.com'>PandaSquad@cimpress.com</a>`"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TreeBrowser from '../TreeBrowser.vue';
import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import ErrorPopup from '../ErrorPopup.vue';
import { Tooltip } from '@cimpress/react-components';

export default {
  name: 'BrowseData',
  inject: ['platformClient'],
  components: {
    TreeBrowser,
    IconInformationCircle,
    ErrorPopup,
    Tooltip
  },
  data() {
    return {
      browse: []
    };
  },
  computed: {
    ...mapState({
      browseData: state => state.search.browseData,
      loadingBrowseData: state => state.search.loadingBrowseData,
      browseDataError: state => state.search.browseDataError
    })
  },
  watch: {
    browseData() {
      this.displayPdws();
    }
  },
  created() {
    this.displayPdws();
  },
  methods: {
    displayPdws() {
      if (
        !this.browseData
        || !this.browseData.results
        || this.browseData.results.length === 0
      ) {
        return;
      }

      const treeData = {};
      this.browseData.results.forEach(item => {
        const cluster = Object.keys(item)[0];
        if (item[cluster].length !== 0) {
          treeData[cluster] = {};
          Object.values(item[cluster]).forEach(database => {
            treeData[cluster][database.key] = {};
            Object.values(database.buckets).forEach(schema => {
              treeData[cluster][database.key][schema.key] = new Set();
              Object.values(schema.buckets).forEach(table => {
                treeData[cluster][database.key][schema.key].add(table.key);
              });
            });
          });
        }
      });

      this.browse = Object.keys(treeData)
        .sort()
        .map(pdwName => ({
          name: pdwName.replace('.eu-west-1', '').toUpperCase(),
          link: pdwName,
          children: Object.keys(treeData[pdwName]).map(databaseName => ({
            name: databaseName,
            link: `${pdwName}.${databaseName}`,
            children: Object.keys(treeData[pdwName][databaseName]).map(
              schemaName => ({
                name: schemaName,
                link: `${pdwName}.${databaseName}.${schemaName}`,
                clickable: true,
                children: Array.from(
                  treeData[pdwName][databaseName][schemaName]
                ).map(itemName => ({
                  name: itemName,
                  clickable: true,
                  link: `${pdwName}.${databaseName}.${schemaName}.${itemName}`
                }))
              })
            )
          }))
        }));
    }
  }
};
</script>
