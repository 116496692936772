<template>
  <div v-if="table && columnData">
    <table class="table table-hover" style="margin-left: 0px;">
      <thead>
        <tr>
          <th v-for="header in columnHeader"
              :key="header.accessor"
              :class="{
                'eight': header.accessor === 'sno',
                'thirty': header.accessor === 'column',
                'forty': header.accessor === 'description',
                'fifteen': header.accessor === 'col_type',
                'twelve': header.accessor === 'pii',
                'nine': header.accessor === 'primaryKey'
              }"
          >
            <div @click="sortType = -sortType; sortColumnBy(header.accessor, sortType);">
              <span style="font-size:1.2em">{{ header.Header }}</span>&nbsp;
              <span v-if="header.accessor === 'pii' && !isOwner" class="fa-lg piiDataWarning">
                <Tooltip direction="top" contents="You should be steward to edit PII status" class="inline">
                  <i class="fa fa-exclamation-triangle text-danger" style="font-size:1.2em" />
                </Tooltip>
              </span>
              <span v-if="header.accessor === 'pii'" class="customlink">
                <a :href="`https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/1353220492/Management+of+PII+data`" target="_blank">
                  <Tooltip direction="top" contents="To read about PII tagging process, click here!" class="inline">
                    <IconInformationCircle size="md" weight="fill" class="text-primary" />
                  </Tooltip>
                </a>
              </span>
              <span v-if="header.accessor === 'primaryKey'">
                <Tooltip direction="top" contents="Primary Key. Only stewards are allowed to edit." class="inline">
                  <span style="font-size:1.2em">PK</span>&nbsp;
                  <i class="fa fa-key text-primary" style="font-size:1.2em" />
                </Tooltip>
              </span>
              <span v-if="header.accessor!=='description'" class="pull-right">
                <i class="fa fa-sort fa-lg" aria-hidden="true" />
              </span>
            </div>
            <br>
            <input v-if="header.accessor!=='col_type' && header.accessor!=='primaryKey' && header.accessor!=='pii'" v-model="searchData[header.accessor]" type="text" value="" style="width: 100%;">
            <div v-else-if="header.accessor === 'col_type'" class="dropdown open">
              <button class="dropdown-toggle" style="width: 100%; text-align:left" @click="dropdownClick= !dropdownClick; getColumnDatatypes();">
                {{ selectDataType }} <span class="caret" />
              </button>
              <ul v-if="dropdownClick" class="dropdown-menu">
                <li v-for="columnsDatatype in columnType" :key="columnsDatatype.id">
                  <button data-toggle="tab" class="text-tile" @click="selectDataType=columnsDatatype; dropdownClick=false">
                    {{ columnsDatatype }}
                  </button>
                </li>
              </ul>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="column in filteredColumnData" :key="column.id">
          <td v-for="header in columnHeader"
              :key="header.accessor"
              :class="{ 'eight': header.accessor === 'sno',
                        'col-type type-font thirty': header.accessor === 'column',
                        'forty': header.accessor === 'description',
                        'type-font fifteen': header.accessor === 'col_type',
                        'twelve': header.accessor === 'pii',
                        'nine': header.accessor === 'primaryKey'
              }"
          >
            <div v-if="updatingPrimaryKey && header.accessor === 'primaryKey' && column === currentColumn" align="left">
              <img src="spinner.svg" width="20" alt="loading">
            </div>
            <div v-if="header.accessor === 'primaryKey'" class="pull-left">
              <input v-show="isUserSteward && !isObjectReadOnly"
                     type="checkbox"
                     :disabled="updatingPrimaryKey || column['isSnowflakePK'] || snowflakePksExist"
                     :checked="column[header.accessor]"
                     @change="clickedColumn = null; primaryKeyEventColumn = column; primaryKeyEventElement = $event; processPrimaryKeyClick()"
              >
            </div>
            <div v-if="header.accessor === 'primaryKey' && column[header.accessor]">
              <Tooltip
                :contents="
                  column.isSnowflakePK
                    ? 'This column has been set as Primary Key on the SQL of the table. It can\'t be changed through this interface.'
                    : 'Primary Key. Only stewards are allowed to edit.' "
                class="pull-right"
              >
                <i class="fa fa-key text-primary" style="font-size:1.2em" />
              </Tooltip>
            </div>
            {{ header.accessor === 'sno'? column[header.accessor]: '' }}
            {{ header.accessor === 'column'? column[header.accessor]: '' }}
            <div v-if="header.accessor === 'description'">
              <div v-if="showColumnDescriptionSpinner && header.accessor === 'description' && clickedColumn === column.sno" align="left">
                <img src="spinner.svg" width="25" alt="loading">
              </div>
              <div v-else style="display: box;">
                {{ header.accessor === 'description'? removeMarkdown(column[header.accessor],150):'' }}
                <button class="btn-icon transparent" @click="clickedColumn = column.sno">
                  <Tooltip v-if="isUserSteward && !isObjectReadOnly" contents="Click to view and edit the column description details">
                    <i class="fa fa-pencil" />
                  </Tooltip>

                  <Tooltip v-else contents="Click to view the column description details">
                    <i class="fa fa-expand" />
                  </Tooltip>
                </button>
              </div>
            </div>
            {{ header.accessor === 'col_type'? column[header.accessor]: '' }}
            <div v-if="header.accessor === 'pii' && column[header.accessor]" class="pull-left fa-lg piiDataWarning">
              <Tooltip direction="top" :contents="piiTooltip(column)" :class="{'scannedPii-badge': isPiiMarkedByScanner(column)}">
                <i class="fa fa-id-card pull-right" style="font-size:26px" />
              </Tooltip>
            </div>
            <img v-if="!piiColsLoaded && markAsPii === column.column && header.accessor === 'pii'" src="spinner.svg" width="25" alt="loading">
            <div v-if="header.accessor === 'pii' && isOwner && !isPiiMarkedByScanner(column)" class="pull-right fa-lg piiDataWarning">
              <label class="switch">
                <input v-show="isUserSteward && !isObjectReadOnly"
                       type="checkbox"
                       :checked="column[header.accessor]"
                       @change="piiColsLoaded = false; clickedColumn = null; updatePiiColumnByCheckbox(column, $event)"
                >
                <span class="slider" />
              </label>
            </div>
            <div v-if="header.accessor === 'pii' && isOwner && isPiiMarkedByScanner(column) && !isPiiCurated(column)" class="pull-right">
              <button class="btn transparent"
                      style="padding-right: 0px; font-size: 18px; padding-left: 0px; padding-top: 0px"
                      @click="clickedPiiUserActions = true; piiActionClicked(column)"
              >
                <Tooltip direction="top" contents="User action required. To perform actions on the PII field, click here!">
                  <i class="fa fa-lg fa-exclamation text-primary" style="padding: 0;" />
                </Tooltip>
              </button>
            </div>
          </td>
        </tr>
        <tr v-if="filteredColumnData.length <= 0">
          <td colspan="4" align="center">
            No column found matching defined filters
          </td>
        </tr>
      </tbody>
    </table>
    <table-column v-if="clickedColumn && openTableColumn"
                  :column="table.columns[clickedColumn-1]"
                  :table="table"
                  :isopen="true"
                  :isUserSteward="isUserSteward"
                  @toggleSpinner="showColumnDescriptionSpinner = !showColumnDescriptionSpinner"
                  @closeModal="clickedColumn = null"
    />
    <ConfirmBox v-if="clickedPiiUserActions"
                :title="`User Actions on PII`"
                :message="descriptionMessageUserActionsPii"
                :confirmBtnTitle="`Confirm`"
                :isPiiAction="true"
                @confirmOperation="updatePiiColumns(currentColumn, 'add')"
                @unmarkOperation="updatePiiColumns(currentColumn, 'remove')"
                @closeModal="clickedPiiUserActions = false; clickedColumnOperation()"
    />
    <ConfirmBox
      v-if="showWarning"
      :title="`Remove Primary Key`"
      :isWarning=true
      :message="`Your dataset <b>won't be stable</b> anymore. Are you sure you want to remove <b>Primary Key</b>?`"
      @confirmOperation="
        () => {
          updatePrimaryKey();
          downgradeTableStatus();
        }
      "
      @closeModal="primaryKeyEventElement.target.checked = !primaryKeyEventElement.target.checked; showWarning = false"
    />
    <error-popup v-if="errorMessage" errorType="snackbar-danger" :message="errorMessage" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import removeMd from 'remove-markdown';
import { Tooltip } from '@cimpress/react-components';
import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import TableColumn from './TableColumn';
import ConfirmBox from '../ConfirmBox';
import React from 'react';
import ErrorPopup from '../ErrorPopup.vue';

export default {
  name: 'TableHeader',
  inject: ['userManager', 'platformClient', 'metadataPlatformClient'],
  components: {
    TableColumn,
    IconInformationCircle,
    Tooltip,
    ConfirmBox,
    ErrorPopup
  },
  data() {
    return {
      columnHeader: [
        { Header: '', accessor: 'primaryKey' },
        { Header: '#', accessor: 'sno' },
        { Header: 'Column', accessor: 'column' },
        { Header: 'Description', accessor: 'description' },
        { Header: 'Type', accessor: 'col_type' },
        { Header: 'PII', accessor: 'pii' }
      ],
      columnData: [],
      snowflakePksExist: false,
      searchData: {
        sno: '',
        column: '',
        description: ''
      },
      clickedColumn: null,
      sortType: 1,
      dropdownClick: false,
      columnType: [],
      selectDataType: 'All',
      piiColsLoaded: true,
      isOwner: '',
      markAsPii: '',
      updatingPrimaryKey: false,
      showColumnDescriptionSpinner: false,
      clickedPiiUserActions: false,
      currentColumn: '',
      openTableColumn: true,
      existingPrimaryKey: [],
      errorMessage: null,
      showWarning: false,
      primaryKeyEventElement: null,
      primaryKeyEventColumn: ''
    };
  },
  computed: {
    ...mapState({
      table: state => state.tables.tableInfo,
      missingStabilityCriteria: state => state.tables.missingStabilityCriteria,
      customFields: state => state.tables.customFields,
      piiData: state => state.pdwPii.piiData,
      isObjectReadOnly: state => state.tables.isObjectReadOnly
    }),
    tableKey() {
      const { cluster, database, schema, name } = this.table || {};
      return `${database}://${cluster}.${schema}/${name}`;
    },
    isUserSteward() {
      const userEmail = this.userManager.userData.app_metadata.canonical_id.toLowerCase();
      return !!this.table.owners.find(owner => owner.email.toLowerCase() === userEmail);
    },
    filteredColumnData() {
      return this.columnData.filter(data => {
        return data.sno.includes(this.searchData.sno)
        && data.column.toUpperCase().includes(this.searchData.column.toUpperCase())
        && (this.searchData.description ? (data.description ? data.description.toUpperCase().includes(this.searchData.description.toUpperCase()) : 0) : 1)
        && data.col_type.toUpperCase().includes(this.selectDataType === 'All' ? '' : this.selectDataType.toUpperCase());
      });
    },
    descriptionMessageUserActionsPii() {
      return `<ul><li> Hitting Confirm button will confirm PII for <b>${this.currentColumn.column}</b> column.</li><li> Hitting Dismiss button will unmark the PII for <b>${this.currentColumn.column}</b> column.</li></ul>`;
    }
  },
  watch: {
    table: {
      immediate: true,
      async handler() {
        if (this.table) {
          this.existingPrimaryKey = this.customFields.primary_key;
          await this.getColumnData();
          this.updateIsOwner();
        }
      }
    },
    async piiData() {
      if (this.piiData) {
        await this.getColumnData();
        this.updateIsOwner();
        this.piiColsLoaded = true;
      }
    }
  },
  methods: {
    ...mapActions({
      putTableStatus: 'tables/putTableStatus',
      putPii: 'pdwPii/putPii',
      patchPii: 'pdwPii/patchPii',
      putTablePrimaryKey: 'tables/putTablePrimaryKey'
    }),
    processPrimaryKeyClick() {
      if (this.customFields.table_status === 'stable' && !this.missingStabilityCriteria.length) {
        this.showWarning = true;
      } else {
        this.updatePrimaryKey();
      }
    },
    async downgradeTableStatus() {
      try {
        await this.putTableStatus({
          platformClient: this.metadataPlatformClient,
          keyValue: this.tableKey,
          tableStatus: 'internal'
        });
      } catch (err) {
        this.errorMessage
          = err.message
          || err.data.message
          || err.data.title
          || (err.status && `Error occurred with status code ${err.status}`)
          || 'Unknown error occurred';
        console.log(err);
      }
    },
    async getColumnData() {
      try {
        const snowflakePks = new Set(
          this.table.columns
            .filter(c => (c.badges || []).find(p => p.badge_name === 'pk'))
            .map(c => c.name.toUpperCase())
        );

        this.columnData = await this.table.columns.map((c, i) => {
          const columnName = c.name.toUpperCase();

          return {
            primaryKey: this.existingPrimaryKey.includes(columnName) || snowflakePks.has(columnName),
            isSnowflakePK: snowflakePks.has(columnName),
            sno: `${i + 1}`,
            column: c.name.toUpperCase(),
            description: c.description,
            col_type: c.col_type !== null ? c.col_type.toUpperCase() : ' ',
            pii: this.isPiiExisting(c.name.toUpperCase()) // Convert column name to Uppercase
          };
        });
        this.snowflakePksExist = Array.from(snowflakePks).length > 0;
      } catch (e) {
        console.log(e);
      }
    },
    async updatePrimaryKey() {
      this.showWarning = false;
      this.currentColumn = this.primaryKeyEventColumn;
      this.updatingPrimaryKey = true;
      this.errorMessage = null;
      let keyValue = `${this.table.database}://${this.table.cluster}.${this.table.schema}/${this.table.name}`;
      let updateData = [...this.customFields.primary_key];
      if (this.primaryKeyEventElement.target.checked) {
        updateData.push(this.primaryKeyEventColumn.column.toUpperCase());
      } else {
        updateData = updateData.filter(pk => pk !== this.primaryKeyEventColumn.column.toUpperCase());
      }
      updateData = Array.from(new Set(updateData));
      try {
        await this.putTablePrimaryKey({ platformClient: this.metadataPlatformClient, keyValue: keyValue, primaryKey: updateData });
      } catch (err) {
        this.primaryKeyEventElement.target.checked = !this.primaryKeyEventElement.target.checked;
        this.errorMessage = err.message || err.data.message || err.data.title || (err.status && `Error occurred with status code ${err.status}`) || 'Unknown error occurred';
        console.log(err);
      }
      this.updatingPrimaryKey = false;
      this.primaryKeyEventElement = null;
      this.primaryKeyEventColumn = '';
    },
    async updatePiiColumnByCheckbox(column, e) {
      this.currentColumn = column;
      this.errorMessage = null;
      if (!this.piiData) {
        let tablePiiData = {
          piiFields: {}
        };
        tablePiiData.piiFields[column.column.toUpperCase()] = {};
        column.pii = false;
        this.markAsPii = column.column;
        try {
          await this.putPii({
            platformClient: this.platformClient,
            account: this.table.cluster.replace('.eu-west-1', ''),
            database: this.table.database.toUpperCase(),
            schema: this.table.schema.toUpperCase(),
            object: this.table.name.toUpperCase(),
            tablePiiData: tablePiiData
          });
        } catch (err) {
          // PII API error response sometime has error in err.data.title.
          this.errorMessage = err.message || err.data.message || err.data.title || (err.status && `Error occurred with status code ${err.status}`) || 'Unknown error occurred.';
          console.log(err);
        }
        column.pii = this.isPiiExisting(column.column);
        this.piiColsLoaded = true;
        this.markAsPii = '';
        return;
      }
      let updateType = '';
      if (e.target.checked) {
        updateType = 'add';
      } else {
        updateType = 'remove';
      }
      await this.updatePiiColumns(column, updateType);
    },
    async updatePiiColumns(column, updateType) {
      column.pii = false;
      this.currentColumn = column;
      this.errorMessage = null;
      this.clickedPiiUserActions = false;
      this.markAsPii = column.column;
      let updateValue = { piiCurated: true };
      if (updateType === 'add') {
        updateValue.piiTagged = true;
      } else {
        updateValue.piiTagged = false;
      }
      let updatePiiData = [
        {
          op: updateType,
          path: `/piiFields/${column.column.toUpperCase()}`,
          value: updateValue
        }
      ];
      try {
        await this.patchPii({
          platformClient: this.platformClient,
          account: this.table.cluster.replace('.eu-west-1', ''),
          database: this.table.database.toUpperCase(),
          schema: this.table.schema.toUpperCase(),
          object: this.table.name.toUpperCase(),
          updatePiiData: updatePiiData
        });
      } catch (err) {
        this.errorMessage = err.message || err.data.message || err.data.title || (err.status && `Error occurred with status code ${err.status}`) || 'Unknown error occurred.';
        console.log(err.data);
      }
      column.pii = this.isPiiExisting(column.column);
      this.piiColsLoaded = true;
      this.markAsPii = '';
      this.clickedColumnOperation();
    },
    removeMarkdown(description, length) {
      let text = removeMd(description);
      if (text.length > length) {
        let trimDescription = text.substring(0, length);
        return `${trimDescription.substr(0, Math.min(trimDescription.length, trimDescription.lastIndexOf(' ')))}...`;
      }
      return text;
    },
    sortColumnBy(field, order) {
      if (field !== 'description') {
        this.columnData.sort(function(current, next) {
          let variable1;
          let variable2;
          if (field === 'sno') {
            variable1 = parseInt(current[field], 10); variable2 = parseInt(next[field], 10);
          } else if (field === 'primaryKey' || field === 'pii') {
            variable1 = current[field]; variable2 = next[field];
          } else {
            variable1 = current[field].toLowerCase(); variable2 = next[field].toLowerCase();
          }
          if (variable1 < variable2) {return -order;}
          if (variable1 > variable2) {return order;}
          return 0;
        });
      }
    },
    getColumnDatatypes() {
      this.columnType = this.columnData.map(a => a.col_type);
      this.columnType = this.columnType.filter(a => a !== ' ');
      this.columnType = Array.from(new Set(this.columnType));
      this.columnType.unshift('All');
    },
    isPiiExisting(column) {
      return !!(this.piiData && this.piiData.piiFields && this.piiData.piiFields[column]
          && ((this.piiData.piiFields[column].detectedPiiTypes && Object.keys(this.piiData.piiFields[column].detectedPiiTypes).length > 0 && !this.piiData.piiFields[column].piiCurated)
          || this.piiData.piiFields[column].piiTagged === true));
    },
    isPiiCurated(column) {
      return this.piiData.piiFields[column.column] && this.piiData.piiFields[column.column].piiCurated;
    },
    isPiiMarkedByScanner(column) {
      return !!(this.piiData && this.piiData.piiFields && this.piiData.piiFields[column.column] && this.piiData.piiFields[column.column].detectedPiiTypes
      && Object.keys(this.piiData.piiFields[column.column].detectedPiiTypes).length > 0 && !this.piiData.piiFields[column.column].piiTagged
      && !this.piiData.piiFields[column.column].piiCurated && !this.piiData.piiFields[column.column].jsonFields);
    },
    isPiiUserTaggedAndDetectedByScanner(column) {
      return !!(this.piiData.piiFields[column.column] && this.piiData.piiFields[column.column].detectedPiiTypes && Object.keys(this.piiData.piiFields[column.column].detectedPiiTypes).length > 0
      && this.piiData.piiFields[column.column].piiTagged);
    },
    piiTooltip(column) {
      let piiTypes = '';
      if (this.piiData.piiFields[column.column] && this.piiData.piiFields[column.column].detectedPiiTypes) {
        piiTypes = `${Object.keys(this.piiData.piiFields[column.column].detectedPiiTypes).join(', ')}`;
      }
      return this.isPiiMarkedByScanner(column) ? React.createElement('div', {},
        React.createElement('p', {}, 'Our scanners detected possible PII data on this column.'),
        React.createElement('p', {}, `PII types detected: ${piiTypes}`)
      ) : this.isPiiUserTaggedAndDetectedByScanner(column) ? React.createElement('div', {},
        React.createElement('p', {}, 'This column has been identified by its stewards as containing PII.'),
        React.createElement('p', {}, 'Our scanners detected possible PII data on this column.'),
        React.createElement('p', {}, `PII types detected: ${piiTypes}`)
      ) : 'This column has been identified by its stewards as containing PII.';
    },
    updateIsOwner() {
      this.isOwner = this.table.owners.filter(owner => owner.email.toLowerCase() === this.userManager.userData.app_metadata.canonical_id.toLowerCase()).length;
    },
    clickedColumnOperation() {
      this.clickedColumn = null;
      this.openTableColumn = true;
    },
    piiActionClicked(column) {
      this.piiColsLoaded = false;
      this.clickedColumn = null;
      this.openTableColumn = false;
      this.currentColumn = column;
    }
  }
};
</script>
<style scoped>
.columnDesc, .text-tile{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table{
  word-break: break-word;
  table-layout: fixed;
}
tbody{
  cursor: pointer;
}
.col-type{
  color: #3a97d3;
}
.type-font{
  font-family: Courier;
}
.tab-heading{
  width: 95%;
  margin: auto;
  color: #00738e;
  font-size: 1.5em;
}
.eight{
  width: 7%;
}
.nine {
  width: 9%;
}
.twelve{
  width: 11%;
}
.fifteen{
  width: 15%
}
.thirty{
  width: 26%
}
.forty{
  width: 35%
}
.scannedPii-badge{
  color: #ee96cc;
}
</style>
