var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',[_vm._v(" Tags "),_c('Tooltip',{staticClass:"inline",attrs:{"direction":"top","contents":"Use tags to enable quick search for related data sources"}},[_c('i',{staticClass:"fa fa-info-circle btn-icon transparent",attrs:{"aria-hidden":"true"}})]),(_vm.userManager.userData.app_metadata.canonical_id && !_vm.isEditing)?_c('button',{staticClass:"btn-icon transparent",on:{"click":function($event){_vm.isEditing = true;
        _vm.errorMessage = null;
        _vm.newTags = _vm.tags.map(function (t) { return t.tag_name; });}}},[_c('Tooltip',{attrs:{"direction":"top","contents":"Edit"}},[_c('i',{staticClass:"fa fa-pencil",attrs:{"aria-hidden":"true"}})])],1):_vm._e()],1),(_vm.processing)?_c('div',{staticClass:"loading inline"},[_c('img',{attrs:{"src":"spinner.svg","width":"25","alt":"loading"}})]):(!_vm.tags.length && !_vm.isEditing)?_c('div',{staticClass:"loading inline",staticStyle:{"color":"grey"}},[_vm._v(" No tags on this object. ")]):(!_vm.isEditing)?_vm._l((_vm.tags),function(tag){return _c('span',{key:tag.id,staticClass:"inline"},[_c('span',{staticClass:"tag",staticStyle:{"margin":"2px"}},[_vm._v(_vm._s(tag.tag_name)+" ")])])}):_vm._e(),(_vm.userManager.userData.app_metadata.canonical_id && _vm.isEditing)?_c('div',{staticStyle:{"padding":"0"}},[_c('Select',{staticClass:"pull-left",staticStyle:{"width":"80%"},attrs:{"isMulti":true,"isClearable":true,"isDisabled":_vm.processing,"label":"Select tags","options":_vm.allTags.tag_usages.map(function (v) { return ({
          label: v.tag_name,
          value: v.tag_name
        }); }),"value":_vm.newTags.map(function (t) { return ({
          label: t,
          value: t
        }); }),"inputValue":_vm.newTagValue},on:{"onInputChange":function (e) { return _vm.newTagValue = e; },"onChange":function (e) { return (_vm.newTags = (e || []).map(function (t) { return t.value; })); }}}),_c('div',{staticClass:"inline-edit-group pull-right",staticStyle:{"padding":"0","margin":"0","min-width":"20%","width":"20%"}},[_c('div',{staticClass:"right-addon"},[_c('button',{attrs:{"title":"Add"},on:{"click":_vm.updateTags}},[_c('i',{staticClass:"fa fa-check",staticStyle:{"margin":"0"},on:{"click":_vm.updateTags}})]),_c('button',{attrs:{"title":"cancel"}},[_c('i',{staticClass:"fa fa-times",staticStyle:{"margin":"0"},on:{"click":function($event){_vm.isEditing = false; _vm.errorMessage = null;}}})])])])],1):_vm._e(),(_vm.errorMessage)?_c('error-popup',{staticStyle:{"padding-top":"5%"},attrs:{"errorType":"alert-danger","message":_vm.errorMessage}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }