<template>
  <div class="card card-block">
    <div class="row">
      <div class="col-md-10" @keyup.enter="triggerSearch">
        <TextField
          :value="search"
          :disabled="searching"
          label="Search for terms related to your Dataset"
          @onChange="(e) => (search = e.target.value)"
        />
      </div>
      <div class="col-md-2">
        <Button
          variant="primary"
          size="lg"
          style="float: right"
          class="search-button"
          :disabled="searching"
          @onClick="triggerSearch"
        >
          <span><i class="fa fa-search" />&nbsp;Search</span>
        </Button>
      </div>
    </div>

    <h6>Filters</h6>
    <div style="display: flex; flex-wrap: wrap">
      <Tooltip
        v-for="filter in filtersData"
        :key="`${filter.name}`"
        direction="bottom"
        variant="popover"
        :contents="filterTooltip(filter)"
        :show="filterBy === filter.name"
        :tooltipStyle="{ maxWidth: 'unset' }"
        @onClickOutside="
          () => {
            if (filterBy === filter.name) {
              filterBy = null;
            }
          }
        "
      >
        <Button
          variant="default"
          size="sm"
          style="margin-right: 6px; margin-top: 6px"
          :disabled="searching"
          @onClick="filterBy = filter.name"
        >
          <span>
            <i :class="`fa ${filter.icon}`" />
            {{
              `${filter.displayName}`
            }}
          </span>
        </Button>
      </Tooltip>
    </div>

    <div v-if="Object.values(filters).some((f) => f.length)">
      <br />
      <div style="display: flex; flex-wrap: wrap">
        <span
          v-for="filter in Object.keys(filters)"
          :key="filter"
          style="display: flex; flex-wrap: wrap"
        >
          <div
            v-for="filterValue in filters[filter]"
            :key="filterValue"
          >
            <Tooltip class="text-warning tag" style="margin: auto 5px auto auto;" :contents="getFilterDisplayName(filter)">
              <span :class="`text-${filtersData.find(d => d.name.toUpperCase() === filter.toUpperCase()).labelStatus}`">
                <i class="fa" :class="filtersData.find(d => d.name.toUpperCase() === filter.toUpperCase()).icon" />
                {{ `&nbsp;${filter === 'domain' ? domainOptions.find(d => d.value === filterValue).label: filterValue}` }}
              </span>
              <button
                class="close"
                @click="
                  filters = Object.assign({}, filters, {
                    [filter]: filters[filter].filter((f) => f !== filterValue)
                  });
                  triggerSearch();
                "
              >
                X
              </button>
            </Tooltip>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { mapState, mapActions } from 'vuex';
import { getDomains } from '../../clients/dataPortal';
import { userAccessibleCluster } from '../../utils/userAccessibility';
import {
  TextField,
  Button,
  Select,
  Tooltip
} from '@cimpress/react-components';

export default {
  name: 'SearchBar',
  inject: ['userManager', 'platformClient'],
  components: {
    TextField,
    Button,
    Tooltip
  },
  props: {
    initialFilters: {
      type: Object,
      required: true
    },
    initialSearch: {
      type: String,
      required: true
    }
  },
  data() {
    const filtersData = [
      { displayName: 'Database', name: 'database', icon: 'fa-database', labelStatus: 'primary' },
      { displayName: 'Schema', name: 'schema', icon: 'fa-th', labelStatus: 'info' },
      { displayName: 'Dataset', name: 'table', icon: 'fa-table', labelStatus: 'info' },
      { displayName: 'Column', name: 'column', icon: 'fa-columns', labelStatus: 'info' },
      { displayName: 'Tag', name: 'tag', icon: 'fa-tag', labelStatus: 'default' },
      { displayName: 'Domain', name: 'domain', icon: 'fa-users', labelStatus: 'warning' },
      { displayName: 'Status', name: 'status', icon: 'fa-heartbeat', labelStatus: 'success' },
      { displayName: 'Ownership', name: 'ownership_role', icon: 'fa-user', labelStatus: 'info' },
      { displayName: 'Data Owner', name: 'squad_email', icon: 'fa-user', labelStatus: 'info' }
    ];

    return {
      search: this.initialSearch,

      filters: this.initialFilters,
      filterBy: null,
      filtersData,

      loadingDomains: false,
      domainOptions: [],
      isUserSuperAdmin: false
    };
  },
  computed: {
    ...mapState({
      searchInput: state => state.search.searchInput,
      searching: state => state.search.searching,
      browseData: state => state.search.browseData,
      loadingBrowseData: state => state.search.loadingBrowseData,
      allTags: state => state.tags.allTags,
      loadingTags: state => state.tags.loadingTags
    }),
    databaseSchemas() {
      const result = {};
      if (this.browseData && this.browseData.results) {
        this.browseData.results.forEach(data => {
          const cluster = Object.keys(data);
          data[cluster].forEach(database => {
            if (!result[database.key]) {
              result[database.key] = new Set();
            }
            Object.values(database.buckets).forEach(schema => { result[database.key].add(schema.key); });
          });
        });
      }
      return result;
    }
  },
  watch: {
    searchInput() {
      this.search = this.searchInput.searchText || '';
      this.filters = this.searchInput.filters || {};
    }
  },
  async created() {
    const userAccountId = this.userManager.userData.app_metadata.account.id;
    const canonicaId = this.userManager.userData.app_metadata.canonical_id;
    let userClusters = await userAccessibleCluster(this.platformClient, canonicaId, userAccountId);
    if (userClusters.length > 1) {
      this.isUserSuperAdmin = true;
    }
    this.loadDomains();
  },
  methods: {
    ...mapActions({
      getSearchResults: 'search/getSearchResults'
    }),
    async loadDomains() {
      this.loadingDomains = true;
      this.domainOptions = [];
      let accountId = this.isUserSuperAdmin ? null : this.userManager.userData.app_metadata.account.id;

      try {
        this.domainOptions = (
          await getDomains({
            platformClient: this.platformClient,
            accountId: accountId
          })
        ).map(d => ({ label: d.name, value: d.domainId }));
      } catch (error) {
        console.error(error);
      }

      this.loadingDomains = false;
    },
    triggerSearch() {
      this.$emit('onSearchInputChange', {
        searchText: this.search,
        filters: this.filters,
        trigger: true
      });
    },
    getFilterDisplayName(filterName) {
      return this.filtersData.find(d => d.name.toLowerCase() === filterName.toLowerCase()).displayName;
    },
    filterTooltip(filterObject) {
      let filterType = filterObject.name;
      let isDisabled = false;
      let selectedValue = null;
      let selectOptions = [];
      let onChange = e => {
        this.filters = Object.assign({}, this.filters, {
          [filterType]: (e || []).map(v => v.value)
        });
      };
      switch (filterType) {
        case 'database':
          isDisabled = this.loadingBrowseData;
          selectOptions = Object
            .keys(this.databaseSchemas)
            .map(v => ({ label: v, value: v }));
          onChange = e => {
            this.filters = Object.assign({}, this.filters, {
              database: (e || []).map(v => v.value),
              schema: []
            });
          };
          break;
        case 'schema':
          isDisabled = this.loadingBrowseData;
          selectOptions = Array.from(
            new Set(
              Object.keys(this.databaseSchemas).reduce((accum, d) => {
                if (
                  !(this.filters.database || []).length || this.filters.database.includes(d)
                ) {
                  accum.push(...this.databaseSchemas[d]);
                }
                return accum;
              }, [])
            )
          ).map(v => ({ label: v, value: v }));
          break;
        case 'tag':
          isDisabled = this.loadingTags;
          selectOptions = (this.allTags.tag_usages || [])
            .map(v => ({ label: v.tag_name, value: v.tag_name }));
          break;
        case 'domain':
          isDisabled = this.loadingDomains;
          selectOptions = this.domainOptions;
          selectedValue = (this.filters[filterType] || [])
            .map(v => ({
              label: this.domainOptions.find(d => d.value === v).label,
              value: v })
            );
          break;
        case 'status':
          selectOptions = ['Stable', 'Investigable', 'Internal', 'Not_set']
            .map(v => ({ label: v.split('_').join(' '), value: v }));
          break;
        case 'table':
        case 'column':
        case 'ownership_role':
          return (
            <TextField
              value={(this.filters[filterType] || [])[0] || ''}
              label={`${filterObject.displayName} name`}
              onChange={e => {
                this.filters = Object.assign({}, this.filters, {
                  [filterType]: e.target.value ? [e.target.value] : []
                });
              }}
            />
          );
        case 'squad_email':
          return (
            <TextField
              value={(this.filters[filterType] || [])[0] || ''}
              label={`${filterObject.displayName} name`}
              onChange={e => {
                this.filters = Object.assign({}, this.filters, {
                  [filterType]: e.target.value ? [e.target.value] : []
                });
              }}
            />
          );
        default:
          console.warn(`Filter ${filterType} not defined`);
          return null;
      }

      return (
        <div style={{ width: '400px' }}>
          <Select
            isMulti
            isClearable
            isDisabled={isDisabled}
            label={`Filter by ${filterType}`}
            onChange={onChange}
            options={selectOptions}
            value={selectedValue
              || (this.filters[filterType] || []).map(v => ({ label: v, value: v }))
            }
          />
        </div>
      );
    }
  }
};
</script>
