<template>
  <div class="list-group">
    <searchResultItems
      v-for="res in searchResult.results"
      :key="res.id"
      :cluster="res.cluster"
      :database="res.database"
      :schema="res.schema"
      :tableName="res.name"
      :description="res.description"
      :owner="res.owner"
      :keyValue="encodeURIComponent(res.key)"
      :columnName="isColumnMatch(res.column_names)"
      :tags="(res.tags || []).map((t) => t.tag_name)"
      :isView="res.is_view"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import searchResultItems from '../searchResultItems';

export default {
  name: 'ListGroup',
  components: {
    searchResultItems
  },
  props: {
    searchText: {
      type: String,
      default: ''
    },
    filters: {
      type: Object,
      default() {}
    }
  },
  computed: {
    ...mapState({
      searchResult: state => state.search.searchResult || []
    })
  },
  methods: {
    isColumnMatch(columns) {
      if (!this.filters.column || !this.filters.column.length) {
        return null;
      }
      const filterValue = this.filters.column[0].substring(1, this.filters.column[0].length - 1);
      let matchcingColumns = columns.filter(c =>
        c.includes(filterValue)
      );
      if (matchcingColumns.length > 5) {
        matchcingColumns = matchcingColumns.slice(0, 5);
        matchcingColumns.push('...');
      }

      return matchcingColumns.join(' | ');
    },
    matchingData(content, searchType, searchValue) {
      let contentList;
      if (searchType === 'column') {
        contentList = content;
      }
      let returnValue = contentList.filter(data => {
        return data.includes(searchValue);
      });

      if (returnValue.length) {
        return returnValue.length >= 5
          ? `${returnValue.slice(0, 5).join(' | ')} ...`
          : returnValue.join(' | ');
      }

      return content.length >= 5
        ? `${content.slice(0, 5).join(' | ')} ...`
        : content.join(' | ');
    }
  }
};
</script>
