<template>
  <div v-if="tableInfo" class="row">
    <div class="col-sm-8" style="padding-left: 0px">
      <div class="card">
        <div class="card-block">
          <Editable
            v-if="!descriptionUpdating"
            :description="
              tableInfo.description === '\n\n' || tableInfo.description === '\n'
                ? ''
                : tableInfo.description
            "
            :entityId="tableKey"
            :entityType="datasetType"
            :disabled="!isUserSteward"
            :isStable="this.customFields.table_status === 'stable'"
            @update:description="tableDescriptionChanged"
            @status:downgrade="downgradeTableStatus()"
          />
          <div v-if="descriptionUpdating" align="left">
            <p style="color: #00738e; font-size: 1.5em; margin-bottom: 0px">
              Description
            </p>
            <hr style="margin-top: 5px; width: 100%" />
            <img src="spinner.svg" width="25" alt="loading" />
          </div>
        </div>
        <error-popup
          v-if="!descriptionUpdating && errorMessage"
          errorType="alert-danger"
          :message="errorMessage"
          class="card-block"
          style="padding: 0px 0px 10px 16px"
        />
      </div>
      <br />
      <div class="card">
        <div class="card-block">
          <PromiseOfFreshness :keyValue="tableKey" @status:downgrade="downgradeTableStatus()" />
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card">
        <div class="card-block">
          <Domain objectType="table" :objectKey="tableKey" :domainId="domainId" :disabled="!isUserSteward" :cluster="tableInfo.cluster" />
          <br />
          <OverviewFields
            heading="Data Owner"
            :keyValue="tableKey"
            :isUserSteward="isUserSteward"
            :isStable="this.customFields.table_status === 'stable'"
            entity="squad_email"
            objectType="table"
            tooltip="The contact information (team email) to get in touch with the data owner. Only stewards are allowed to edit."
            @status:downgrade="downgradeTableStatus()"
          />

          <div v-if="!isDataOwnerPresent">
            <PotentialDataOwner :tableInfo="tableInfo" />
            <br />
          </div>

          <OwnershipRole :ownershipRole="tableInfo.ownership_role" :cluster="tableInfo.cluster" :objectType="datasetType" />
          <br />
          <Stewards
            :keyValue="tableKey"
            objectType="table"
            :isStable="this.customFields.table_status === 'stable'"
            @status:downgrade="downgradeTableStatus()"
          />
          <br />

          <OverviewFields
            heading="Slack Channel"
            :keyValue="tableKey"
            :isUserSteward="isUserSteward"
            :isStable="this.customFields.table_status === 'stable'"
            entity="slack_channel"
            objectType="table"
            :objectCluster="tableInfo.cluster"
            tooltip="The monitored support channel in Slack to get in touch with the data owner. Only stewards are allowed to edit."
            @status:downgrade="downgradeTableStatus()"
          />
          <div>
            <h5 class="inline">
              Frequent Users
            </h5>
            <Tooltip
              direction="top"
              contents="Top 5 Frequent Users of this table in the last 30 days"
              class="inline"
            >
              <IconInformationCircle size="md" weight="fill" class="text-primary" />
            </Tooltip>
            <frequent-users />
          </div>
          <br />
          <Tags :keyValue="tableKey" :objectType="'table'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import Stewards from '../shared/overview/Stewards';
import OverviewFields from '../shared/overview/OverviewFields';
import Editable from '../shared/overview/Editable';
import OwnershipRole from '../shared/overview/OwnershipRole';
import Tags from '../shared/overview/Tags';
import FrequentUsers from './Overview/FrequentUsers';
import PromiseOfFreshness from './Overview/PromiseOfFreshness';
import ErrorPopup from '../ErrorPopup.vue';
import Domain from '../shared/overview/Domain.vue';
import PotentialDataOwner from '../shared/overview/PotentialDataOwner';

export default {
  name: 'OverviewTab',
  inject: ['userManager', 'metadataPlatformClient'],
  components: {
    Editable,
    IconInformationCircle,
    Tooltip,
    OwnershipRole,
    Tags,
    Stewards,
    FrequentUsers,
    OverviewFields,
    PromiseOfFreshness,
    ErrorPopup,
    Domain,
    PotentialDataOwner
  },
  data() {
    return {
      Active: 'overview',
      hover: false,
      showTooltip: false,
      isAddSteward: false,
      descriptionUpdating: false,
      errorMessage: null,
      datasetType: null
    };
  },
  computed: {
    ...mapState({
      tableInfo: state => state.tables.tableInfo,
      customFields: state => state.tables.customFields
    }),
    tableKey() {
      const { cluster, database, schema, name } = this.tableInfo || {};
      return `${database}://${cluster}.${schema}/${name}`;
    },
    tableKeyDecoded() {
      return encodeURIComponent(this.tableKey);
    },
    isUserSteward() {
      const userEmail = this.userManager.userData.app_metadata.canonical_id.toLowerCase();
      return !!this.tableInfo.owners.find(
        userData => userData.email.toLowerCase() === userEmail
      );
    },
    domainId() {
      const domainPD = (this.tableInfo.programmatic_descriptions || [])
        .find(b => b.source === 'domain_id') || {};
      return domainPD.text;
    },
    isDataOwnerPresent() {
      const pd = (this.tableInfo.programmatic_descriptions || [])
        .find(b => b.source === 'squad_email') || {};
      return pd.text && pd.text.length > 2;
    }
  },
  created() {
    if (this.tableInfo) {
      this.datasetType = this.tableInfo.is_view ? 'View' : 'Table';
    }
  },
  methods: {
    ...mapActions({
      putTableStatus: 'tables/putTableStatus',
      putTableDescription: 'tables/putTableDescription'
    }),
    async downgradeTableStatus() {
      try {
        await this.putTableStatus({
          platformClient: this.metadataPlatformClient,
          keyValue: this.tableKey,
          tableStatus: 'internal'
        });
      } catch (err) {
        this.errorMessage
          = err.message
          || err.data.message
          || err.data.title
          || (err.status && `Error occurred with status code ${err.status}`)
          || 'Unknown error occurred';
        console.log(err);
      }
    },
    async tableDescriptionChanged(event) {
      this.descriptionUpdating = true;
      this.errorMessage = null;
      try {
        await this.putTableDescription({
          platformClient: this.metadataPlatformClient,
          keyValue: this.tableKeyDecoded,
          description: event.description === '' ? '\n\n' : event.description
        });
        this.$ga.event('table', 'edit', unescape(this.tableKeyDecoded), 1);
      } catch (e) {
        this.errorMessage
          = e.message
          || e.data.message
          || e.data.title
          || (e.status && `Error occurred with status code ${e.status}`)
          || 'Unknown error occurred.';
        console.log(e);
      }
      this.descriptionUpdating = false;
    }
  }
};
</script>
