<template>
  <div v-click-outside="() => (showDropdown = false)">
    <Button variant="anchor" @onClick="showDropdown = !showDropdown">
      <i :class="`fa fa-download ${iconSize}`" style="color: #0088a9" />
      &nbsp;{{ downloadTitle }}
    </Button>

    <div class="dropdown" :class="showDropdown ? 'open' : 'close'">
      <ul class="dropdown-menu" style="right: 0; left: unset;">
        <li v-if="!processing">
          <a @click="() => download('csv')">Download as CSV</a>
          <a @click="() => download('json')">Download as JSON</a>
        </li>
        <li v-if="processing" align="center">
          <img src="spinner.svg" width="35px" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Button } from '@cimpress/react-components';
const { Parser } = require('json2csv');
import vClickOutside from 'v-click-outside';

export default {
  name: 'DownloadData',
  components: {
    Button
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    fileName: {
      type: String,
      default: 'Download'
    },
    downloadTitle: {
      type: String,
      default: 'Download'
    },
    iconSize: {
      type: String,
      default: 'fa-lg'
    },
    fetchData: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      showDropdown: false,
      processing: false,
      errorDownloading: null
    };
  },
  computed: {
    ...mapState({
      searchInput: state => state.search.searchInput
    })
  },
  methods: {
    async download(downloadType) {
      this.processing = true;
      this.errorDownloading = null;
      try {
        const result = await this.fetchData();
        let blob;

        switch (downloadType) {
          case 'csv': {
            const csvData = new Parser().parse(result).replaceAll('#', '');
            blob = `data:text/csv;sep=;charset=utf-8,%EF%BB%BF${csvData}`;
            break;
          }
          case 'json': {
            const binaryObj = new Blob([JSON.stringify(result, null, 2)], {
              type: 'application/json'
            });
            blob = window.URL.createObjectURL(binaryObj);
            break;
          }
          default:
            throw new Error(`Download type ${downloadType} not supported.`);
        }

        const anchor = document.createElement('a');
        anchor.setAttribute('href', blob);
        anchor.setAttribute('target', '__blank');
        anchor.setAttribute('download', `${this.fileName}.${downloadType}`);

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } catch (e) {
        this.errorDownloading
          = e.message
          || e.data.message
          || e.data.title
          || (e.status && `Error occurred with status code ${e.status}`)
          || 'Unexpected error occurred';
      }

      this.processing = false;
    }
  }
};
</script>
