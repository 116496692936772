
export const getDataObjectSignedUrl = async ({ platformClient, configurationId, userAttributeValues, account, database, schema, objectName }) => {
  const response = await platformClient.post(
    'https://nala.cimpress.io/v1/embed/generateLink',
    {
      configurationId,
      userAttributeValues: userAttributeValues,
      filters: [
        {
          filter: 'accountName',
          filterValue: account.toUpperCase()
        },
        {
          filter: 'database',
          filterValue: database.toUpperCase()
        },
        {
          filter: 'schema',
          filterValue: schema.toUpperCase()
        },
        {
          filter: 'object',
          filterValue: encodeURIComponent(objectName.toUpperCase())
        },
        {
          filter: 'sdk',
          filterValue: 2
        },
        {
          filter: 'embed_domain',
          filterValue: window.location.origin
        }
      ]
    }
  );

  return response.data.signedEmbedUrl;
};
