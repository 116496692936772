<template>
  <div>
    <div
      class="modal fade in"
      tabindex="-1"
      style="visibility: visible; display: block; padding-left: 0px"
    >
      <div class="modal-dialog foobar" style="z-index: 1050; width: 80%">
        <div class="modal-content modal-info">
          <div class="modal-header">
            <div class="modal-title">
              {{ column.name.toUpperCase() }}
            </div>
            <button
              class="fa fa-times fa-lg close"
              style="font-size: 2.5rem"
              @click="closeModal()"
            />
          </div>
          <div class="modal-body" style="padding-top: 0px">
            <div>
              <br />
              <span
                class="pull-right customlink"
                @click="columnHistoryOn = true"
              >
                <Tooltip direction="top" contents="History">
                  <IconSynchronizeArrowClock size="lg" />
                </Tooltip>
              </span>
              <Editable
                v-if="!columnDescriptionUpdating"
                :description="
                  column.description === '\n\n' || column.description === '\n'
                    ? ''
                    : column.description
                "
                :entityId="tableColEntity"
                :entityType="'Column'"
                :disabled="!isUserSteward || disabled"
                @update:description="columnDescriptionChanged"
              />
              <div
                v-if="columnDescriptionUpdating && !errorMessage"
                align="left"
              >
                <p style="color: #00738e; font-size: 1.5em; margin-bottom: 0px">
                  Description
                </p>
                <hr style="margin-top: 5px; width: 100%" />
                <img src="spinner.svg" width="25" alt="loading" />
              </div>
              <error-popup
                v-if="errorMessage"
                errorType="alert-danger"
                :message="errorMessage"
                style="padding: 10px 0px 10px 0px"
              />
            </div>
          </div>
          <div class="modal-footer">
            <!-- Blank Footer -->
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade in" @click="closeModal()" />
    </div>
    <EditHistoryTable
      v-if="columnHistoryOn"
      :entityId="tableColEntity"
      :entityType="'Column'"
      @closeModal="columnHistoryOn = false"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import Editable from '../shared/overview/Editable';
import EditHistoryTable from '../EditHistoryTable';
import IconSynchronizeArrowClock from '@cimpress-technology/react-streamline-icons/lib/IconSynchronizeArrowClock';
import ErrorPopup from '../ErrorPopup.vue';

export default {
  name: 'TableColumns',

  inject: ['metadataPlatformClient'],

  components: {
    Editable,
    EditHistoryTable,
    IconSynchronizeArrowClock,
    Tooltip,
    ErrorPopup
  },
  props: {
    table: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    },
    isUserSteward: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tableColEntity: `${this.table.database}://${this.table.cluster}.${this.table.schema}/${this.table.name}/column/${this.column.name}`,
      columnHistoryOn: false,
      columnDescriptionUpdating: false,
      errorMessage: null
    };
  },
  methods: {
    ...mapActions({
      putColumnDescription: 'tables/putColumnDescription'
    }),

    async columnDescriptionChanged(event) {
      this.$emit('toggleSpinner');
      this.errorMessage = null;
      this.columnDescriptionUpdating = true;
      let tableKey = `${this.table.database}://${this.table.cluster}.${this.table.schema}/${this.table.name}`;
      let tableKeyDecoded = encodeURIComponent(tableKey);
      try {
        await this.putColumnDescription({
          platformClient: this.metadataPlatformClient,
          keyValue: tableKeyDecoded,
          columnName: this.column.name,
          description: event.description === '' ? '\n\n' : event.description
        });
        this.$ga.event(
          'column',
          'edit',
          `${unescape(tableKey)}.${this.column.name}`,
          1
        );
      } catch (e) {
        this.errorMessage
          = e.message
          || e.data.message
          || e.data.title
          || (e.status && `Error occurred with status code ${e.status}`)
          || 'Unknown error occurred.';
        console.log(e);
      }
      this.columnDescriptionUpdating = false;
      this.$emit('toggleSpinner');
    },
    closeModal() {
      this.$emit('closeModal');
    }
  }
};
</script>

<style scoped>
.col-type {
  color: #3a97d3;
}
</style>
