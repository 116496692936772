
export const getDomains = async ({ platformClient, accountId }) => {
  let response = null;
  response = await platformClient.get(`https://api.data.cimpress.io/v0/domains?isDataDomain=true${accountId ? `&accountId=${accountId}` : ''}`);
  return response.data.results;
};

export const getDomainDetails = async ({ platformClient, domainId }) => {
  const response = await platformClient.get(`https://api.data.cimpress.io/v0/domains/${domainId}`);
  return response.data;
};
