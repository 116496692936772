<template>
  <a class="comments-tab-header" style="margin-left: 0px; display: flex;">
    Comments
    &nbsp;
    <span v-if="loading">(...)</span>
    <span v-else-if="!commentsCount">(0)</span>
    <CommentIndicator
      v-else
      :resourceUri="`https://api.data-discovery.cimpress.io/v0/data-sources/snowflake/data-objects/${dataObjectTypeMapper[objectType]}/${objectKey}`"
      :accessToken="platformClient.tokenResolverFunction()"
      :refreshInterval="300"
    />
  </a>
</template>

<script>
import { CommentIndicator } from '@cimpress-technology/react-comments';
import { convertItemKeyToWrapperObjectKey } from '../../utils/objectKeyConversion';

export default {
  name: 'CommentsTabHeader',
  inject: ['platformClient'],
  components: {
    CommentIndicator
  },
  props: {
    itemKey: {
      type: String,
      required: true
    },
    objectType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      commentsCount: 0
    };
  },
  watch: {
    itemKey: {
      immediate: true,
      async handler() {
        this.dataObjectTypeMapper = {
          table: 'dataset',
          schema: 'schema'
        };
        this.loading = true;
        this.objectKey = convertItemKeyToWrapperObjectKey(this.itemKey, this.dataObjectTypeMapper[this.objectType]);
        try {
          const resourceUri = `https://api.data-discovery.cimpress.io/v0/data-sources/snowflake/data-objects/${this.dataObjectTypeMapper[this.objectType]}/${this.objectKey}`;
          const commentUrl = `https://comment.trdlnk.cimpress.io/v0/resources/${encodeURIComponent(
            resourceUri
          )}`;
          const res = await this.platformClient.get(commentUrl);
          this.commentsCount = (res.data.comments || []).length;
        } catch (error) {
          if (error.status !== 404) {
            console.error(error);
          }
          this.commentsCount = 0;
        }

        this.loading = false;
      }
    }
  }
};
</script>
<style>
.comments-tab-header .comment-indicator {
  font-size: unset;
}
</style>
