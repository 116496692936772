<template>
  <div class="row">
    <div class="col-sm-8">
      <h5>Comments</h5>
      <Comments
        :resourceUri="`https://api.data-discovery.cimpress.io/v0/data-sources/snowflake/data-objects/${dataObjectTypeMapper[objectType]}/${objectKey}`"
        :newestFirst="true"
        :editComments="true"
        :deleteComments="true"
        :autoFocus="false"
        :showVisibilityLevels="false"
        :enforceVisibilityLevel="'public'"
        :accessToken="platformClient.tokenResolverFunction()"
        :emailing="{
          enabled: !!(owners && owners.length),
          newCommentsTemplateId: '15993ff7-c758-4b7b-a385-38ec4b7f1f2e',
          newCommentsTemplatePayload: {
            to: owners.join('; '),
            objectType: dataObjectTypeMapper[objectType],
            itemKey: objectKey,
            ddLink: `https://data-discovery.cimpress.io/#/?selectedTreeItem=${itemKey}&tab=comments`
          }
        }"
      />
    </div>

    <div class="col-sm-4">
      <Stewards class="card" style="padding: 15px; padding-bottom: 24px" :keyValue="itemKey" :objectType="objectType" :readOnly="true" />
    </div>
  </div>
</template>

<script>
import { Comments } from '@cimpress-technology/react-comments';
import { convertItemKeyToWrapperObjectKey } from '../../utils/objectKeyConversion';
import Stewards from './overview/Stewards.vue';

export default {
  name: 'TableTabs',
  inject: ['platformClient'],
  components: {
    Comments,
    Stewards
  },
  props: {
    itemKey: {
      type: String,
      required: true
    },
    objectType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dataObjectTypeMapper: {
        table: 'dataset',
        schema: 'schema'
      }
    };
  },
  computed: {
    owners() {
      return this.$store.state[`${this.objectType}s`][
        `${this.objectType}Info`
      ].owners.map(userData => userData.email);
    },
    objectKey() {
      return convertItemKeyToWrapperObjectKey(this.itemKey, this.dataObjectTypeMapper[this.objectType]);
    }
  }
};
</script>
