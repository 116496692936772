
export default function normalizeSearchFilters(filters) {
  const parsedFilters = {};
  Object.keys(filters).forEach(filterKey => {
    if (!filters[filterKey].length) {
      return;
    }
    let filterName;
    let filterValue = filters[filterKey];

    switch (filterKey) {
      case 'domain':
        filterName = 'domain_id';
        break;
      case 'status':
        filterName = 'table_status';
        break;
      case 'table':
      case 'column':
        filterName = filterKey;
        filterValue = [`*${filters[filterKey]}*`];
        break;
      default:
        filterName = filterKey;
        break;
    }

    parsedFilters[filterName] = filterValue;
  });

  return parsedFilters;
}
