<template>
  <div>
    <div v-if="!lookerGrapherData && !error" align="center">
      <img src="spinner.svg" alt="loading">
    </div>
    <div v-if="result && !error">
      <TreeBrowser
        v-for="project in result"
        :key="project.name"
        :node="project"
        treeType="grapherTree"
      />
    </div>
    <div v-if="error">
      <div class="alert alert-dismissible alert-warning">
        <p style="margin-bottom: 0px;">
          {{ error }}
          You can contact Data Platform team at <a href="mailto:PandaSquad@cimpress.com">PandaSquad@cimpress.com</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TreeBrowser from '../../TreeBrowser.vue';

export default {
  name: 'LookerGrapherTree',
  components: {
    TreeBrowser
  },
  data() {
    return {
      result: [],
      error: '',
      lookerGrapherData: ''
    };
  },
  computed: {
    ...mapState({
      cachedLookerGrapherData: state => state.lookerGrapher.cachedLookerGrapherData,
      newLookerGrapherData: state => state.lookerGrapher.newLookerGrapherData,
      lookerGrapherError: state => state.lookerGrapher.lookerGrapherError
    }),
    isNewLookerGrapherDataAvailable() {
      if (this.newLookerGrapherData.data && Object.keys(this.newLookerGrapherData.data).length > 0) {
        return true;
      }
      return false;
    },
    isCachedLookerGrapherDataAvailable() {
      if (this.cachedLookerGrapherData.data && Object.keys(this.cachedLookerGrapherData.data).length > 0) {
        return true;
      }
      return false;
    }
  },
  watch: {
    async newLookerGrapherData() {
      if (this.isNewLookerGrapherDataAvailable) {
        this.lookerGrapherData = this.newLookerGrapherData;
        await this.formTree();
      }
    },
    async cachedLookerGrapherData() {
      if (this.isCachedLookerGrapherDataAvailable) {
        this.lookerGrapherData = this.cachedLookerGrapherData;
        await this.formTree();
      }
    },
    lookerGrapherError() {
      // If cached looker grapher is available and there is an error in new looker grapher data, we don't show error in UI
      if (this.lookerGrapherError && !this.isCachedLookerGrapherDataAvailable) {
        this.errorMessage(this.lookerGrapherError);
      }
    }
  },
  mounted() {
    if (this.isNewLookerGrapherDataAvailable) {
      this.lookerGrapherData = this.newLookerGrapherData;
      this.formTree();
    } else if (this.isCachedLookerGrapherDataAvailable) {
      this.lookerGrapherData = this.cachedLookerGrapherData;
      this.formTree();
    } else if (this.lookerGrapherError) {
      this.errorMessage(this.lookerGrapherError);
    }
  },
  methods: {
    errorMessage(e) {
      console.log(e);
      if (e.status === 404) {
        this.error = "Couldn't detect any Looker Dependencies for this table.";
      } else if (e.status === 500) {
        this.error = e.data.Message;
      } else {
        this.error = e;
      }
    },
    formTree() {
      this.result = []; // To remove cached looker grapher tree before loading the new (fresh) one
      let grapherObj = this.lookerGrapherData.data;
      for (let project in grapherObj) {
        this.result.push({ name: project, children: this.getModels(grapherObj[project]) });
      }
    },
    getModels(shares) {
      let models = [];
      for (let model in shares) {
        models.push({ name: model !== 'orphan' ? model : 'none', children: this.getExpolores(shares[model]) });
      }
      return models.sort();
    },
    getExpolores(models) {
      let explores = [];
      for (let explore in models) {
        explores.push({ name: explore !== 'orphan' ? explore : 'none', children: this.getViews(models[explore]) });
      }
      return explores.sort();
    },
    getViews(explores) {
      let views = [];
      for (let view in explores[0]) {
        views.push({ name: explores[0][view], type: view });
      }
      return views;
    }
  }
};
</script>
