<template>
  <div>
    <div class="accordion">
      <div class="accordion-header" @click="graphTab = !graphTab">
        <div class="accordion-arrow">
          <i class="fa fa-lg" :class="{'fa-angle-down': graphTab, 'fa-angle-right': !graphTab}" />
        </div>
        <strong>Lineage Graph</strong>
        <Label v-if="isLookerGrapherNewOrCachedOrError !== null" :text="isLookerGrapherNewOrCachedOrError" status="info" class="inline pull-right" />
        <div v-else class="inline pull-right" align="center">
          <img src="spinner.svg" width="25" alt="loading">
        </div>
      </div>
      <div v-if="graphTab" class="accordion-body">
        <lookerGrapher />
      </div>
    </div>
    <div class="accordion">
      <div class="accordion-header" @click="jsonTab = !jsonTab">
        <div class="accordion-arrow">
          <i class="fa fa-lg" :class="{'fa-angle-down': jsonTab, 'fa-angle-right': !jsonTab}" />
        </div>
        <strong>Json Tree</strong>
        <Label v-if="isLookerGrapherNewOrCachedOrError !== null" :text="isLookerGrapherNewOrCachedOrError" status="info" class="inline pull-right" />
        <div v-else class="inline pull-right" align="center">
          <img src="spinner.svg" width="25" alt="loading">
        </div>
      </div>
      <div v-if="jsonTab" class="accordion-body">
        <lookerGrapherTree />
      </div>
    </div>
  </div>
</template>

<script>
import lookerGrapherTree from './LookerGrapher/LookerGrapherTree';
import lookerGrapher from './LookerGrapher/LookerGrapher';
import { Label } from '@cimpress/react-components';
import { mapState } from 'vuex';
export default {
  name: 'LookerGrapherTab',
  components: {
    lookerGrapher,
    lookerGrapherTree,
    Label
  },
  data() {
    return {
      graphTab: true,
      jsonTab: false
    };
  },
  computed: {
    ...mapState({
      newLookerGrapherData: state => state.lookerGrapher.newLookerGrapherData,
      cachedLookerGrapherData: state => state.lookerGrapher.cachedLookerGrapherData,
      lookerGrapherError: state => state.lookerGrapher.lookerGrapherError
    }),
    isLookerGrapherNewOrCachedOrError() {
      let lookerGrapherType = null;
      if (this.newLookerGrapherData.data && Object.keys(this.newLookerGrapherData.data).length > 0) {
        lookerGrapherType = 'Fresh';
      } else if (this.cachedLookerGrapherData.data && Object.keys(this.cachedLookerGrapherData.data).length > 0) {
        lookerGrapherType = 'Cached';
      } else if (this.lookerGrapherError && lookerGrapherType !== 'Cached') {
        lookerGrapherType = '';
      }
      return lookerGrapherType;
    }
  }
};
</script>

<style scoped>
.accordion-body{
  padding-top: 20px !important;
}
</style>
