<template>
  <div>
    <h5 class="inline">
      Ownership Role
    </h5>
    <Tooltip
      :contents="`Snowflake Role having ownership grant on this ${objectType}.`"
      class="inline"
    >
      <IconInformationCircle size="md" weight="fill" class="text-primary" />
    </Tooltip>
    <br />
    <div v-if="ownershipRole" style="margin: 2px" class="tag tag-default">
      {{ ownershipRole }}
    </div>
    <div v-else class="text-muted">
      No snowflake role in account {{ cluster.split('.')[0] }} has ownership grant on this {{ objectType }}
    </div>
  </div>
</template>
<script>
import { Tooltip } from '@cimpress/react-components';
import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
export default {
  name: 'OwnershipRole',
  components: {
    Tooltip,
    IconInformationCircle
  },
  props: {
    ownershipRole: {
      type: String,
      required: true
    },
    objectType: {
      type: String,
      required: true
    },
    cluster: {
      type: String,
      required: true
    }
  }
};
</script>
