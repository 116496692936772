
export const getCimpressEmployeeDetails = async (platformClient, email) => {
  const response = await platformClient.get(`https://orgchart.fi.cimpress.io/v0/employees/${encodeURIComponent(email)}`);
  return response.data;
};

export const getCimpressSquadDetails = async (platformClient, squadId) => {
  const response = await platformClient.get(`https://orgchart.fi.cimpress.io/v0/squads/${encodeURIComponent(squadId)}`);
  return response.data;
};
