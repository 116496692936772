<template>
  <div v-if="userDetails">
    <h4>Recently Accessed Datasets</h4>

    <div>
      <a
        v-for="table in (userDetails.recentTables || []).slice(0, 10).reverse()"
        :key="table.key"
        @click="
          setSelectedTreeItem({
            selectedTreeItem: `${table.cluster}.${table.database}.${table.schema}.${table.table_name}`
          })
        "
      >
        <div class="inline tag text-tile">
          {{ table.table_name }}
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'RecenTablesSection',
  computed: {
    ...mapState({
      userDetails: state => state.customizr.userDetails
    })
  },
  methods: {
    ...mapActions({
      setSelectedTreeItem: 'search/setSelectedTreeItem'
    })
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.text-tile {
  color: #0088a9;
  font-size: 0.9em;
  margin: 3px 8px 0px 0px;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.text-tile:hover {
  color: #fff;
  background-color: #0088a9;
}
</style>
