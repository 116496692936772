<template>
  <div>
    <div class="modal fade in" tabindex="-1" style="visibility: visible; display: block; padding-left: 0px;">
      <div class="modal-dialog foobar" style="z-index: 1050; width: 75%;">
        <div class="modal-content modal-info">
          <div class="modal-header" />
          <div class="modal-body" style="padding-top: 0px">
            <div style="overflow-x:auto;">
              <h3> {{ entityType }} Edit History </h3>
              <table>
                <tr>
                  <th>Action</th>
                  <th>New Description</th>
                  <th>Performed by</th>
                  <th>Date</th>
                </tr>
                <tr v-for="result in results" :key="result.rec_timestamp">
                  <td width="20%">
                    {{ result.type }}
                  </td>
                  <td width="30%">
                    <!-- eslint-disable -->
                    <div style="word-wrap: break-word;" v-html="checkAndModifyEditHistoryDescription(result.details)" />
                    <!-- eslint-enable -->
                  </td>
                  <td width="20%">
                    {{ result.principal }}
                  </td>
                  <td width="20%">
                    {{ convertDate(result.rec_timestamp) }}
                  </td>
                </tr>
              </table>
            </div>
            <div v-if="!results && !errorMessage" class="card-block">
              <img src="spinner.svg" width="50px" alt="loading">
            </div>
            <error-popup v-if="errorMessage" errorType="alert-danger" :message="errorMessage" class="card-block" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" @click="$emit('closeModal')">
              Close
            </button>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade in" @click="$emit('closeModal')" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ErrorPopup from './ErrorPopup.vue';

export default {
  name: 'EditHistoryTable',
  components: { ErrorPopup },
  inject: ['platformClient'],
  props: {
    entityId: {
      type: String,
      default: () => 'empty',
      required: true
    },
    entityType: {
      type: String,
      default: () => '',
      required: true
    }
  },
  data() {
    return {
      results: '',
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      editHistory: state => state.trails,
      tableInfo: state => state.tables.tableInfo
    })
  },
  async created() {
    this.errorMessage = null;
    try {
      await this.getEntityHistory({ platformClient: this.platformClient, entityId: this.entityId });
      this.results = this.editHistory.editHistory.items;
      if (this.entityType === 'Steward') {
        this.results = this.results.filter(result => result.details.description.length > 6).slice(0, 20);
      }
    } catch (e) {
      this.errorMessage = e.message || e.data.message || e.data.title || (e.status && `Error occurred with status code ${e.status}`) || 'Unknown error occurred';
      console.log(e);
    }
  },
  methods: {
    ...mapActions({
      getEntityHistory: 'trails/getEntityHistory'
    }),
    convertDate(dateIso) {
      let date = new Date(dateIso);
      return date.toUTCString();
    },
    checkAndModifyEditHistoryDescription(details) {
      if (this.entityType === 'Promise Of Freshness') {
        let promise_of_freshness = details.description.pof_duration === 0 ? '' : `Table content reflects the promise of freshness of <b>${details.description.pof_duration}
          ${details.description.pof_duration_filter}</b>. The last update time of the table can be identified using <b>${details.description.pof_reference_column.toLowerCase()}</b> column.`;
        return promise_of_freshness;
      } else if (this.entityType === 'Table Status') {
        return details.description ? details.description.charAt(0).toUpperCase() + details.description.slice(1) : 'Not set';
      } else if (Object.prototype.hasOwnProperty.call(details, 'description')) {
        return details.description;
      }
      return details;
    }
  }
};
</script>

<style scoped>
.col-type{
  color: #3a97d3
}
table {
  width: 100%;
}
th, td {
  border-bottom: 1.5px solid #ddd;
  padding: 10px;
  max-width:650px;
  min-width:100px;
}
tr:hover {background-color: #f5f5f5;}

</style>
