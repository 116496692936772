<template>
  <div>
    <div v-if="!lookerGrapherData && !error" align="center">
      <img src="spinner.svg" alt="loading">
    </div>
    <div v-if="result && !error" class="legend">
      <div v-for="i in 4" :key="i">
        <svg width="200" height="20">
          <rect x="00"
                y="0"
                rx="2"
                ry="2"
                width="20"
                height="20"
                :style="{fill: legend.color[i-1]}"
          />
          <text x="30" y="15" fill="#00738e">{{ legend.value[i-1] }}</text>
        </svg>
      </div>
    </div>
    <div id="lookerTree" />
    <div v-if="error">
      <div class="alert alert-dismissible alert-warning">
        <p style="margin-bottom: 0px;">
          {{ error }}
          You can contact Data Platform team at <a href="mailto:PandaSquad@cimpress.com">PandaSquad@cimpress.com</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import { mapState } from 'vuex';

export default {
  name: 'LookerGrapher',
  data() {
    return {
      graphData: {
        nodes: [],
        links: []
      },
      legend: {
        value: ['Table', 'Looker View', 'Explore', 'Model'],
        color: ['rgb(214, 39, 40)', 'rgb(44, 160, 44)', 'rgb(255, 127, 14)', 'rgb(31, 119, 180)']
      },
      childCount: [1, 1, 1, 1],
      maxLevel: 1,
      result: '',
      error: '',
      lookerGrapherData: ''
    };
  },
  computed: {
    ...mapState({
      cachedLookerGrapherData: state => state.lookerGrapher.cachedLookerGrapherData,
      newLookerGrapherData: state => state.lookerGrapher.newLookerGrapherData,
      lookerGrapherError: state => state.lookerGrapher.lookerGrapherError
    }),
    isNewLookerGrapherDataAvailable() {
      if (this.newLookerGrapherData.data && Object.keys(this.newLookerGrapherData.data).length > 0) {
        return true;
      }
      return false;
    },
    isCachedLookerGrapherDataAvailable() {
      if (this.cachedLookerGrapherData.data && Object.keys(this.cachedLookerGrapherData.data).length > 0) {
        return true;
      }
      return false;
    }
  },
  watch: {
    async newLookerGrapherData() {
      if (this.isNewLookerGrapherDataAvailable) {
        this.lookerGrapherData = this.newLookerGrapherData;
        await this.createGraphData();
        await this.mountGraph();
      }
    },
    async cachedLookerGrapherData() {
      if (this.isCachedLookerGrapherDataAvailable) {
        this.lookerGrapherData = this.cachedLookerGrapherData;
        await this.createGraphData();
        await this.mountGraph();
      }
    },
    lookerGrapherError() {
      // If cached looker grapher is available and there is an error in new looker grapher data, we don't show error in UI
      if (this.lookerGrapherError && !this.isCachedLookerGrapherDataAvailable) {
        this.errorMessage(this.lookerGrapherError);
      }
    }
  },
  async mounted() {
    if (this.isNewLookerGrapherDataAvailable) {
      this.lookerGrapherData = this.newLookerGrapherData;
      await this.createGraphData();
      await this.mountGraph();
    } else if (this.isCachedLookerGrapherDataAvailable) {
      this.lookerGrapherData = this.cachedLookerGrapherData;
      await this.createGraphData();
      await this.mountGraph();
    } else if (this.lookerGrapherError) {
      this.errorMessage(this.lookerGrapherError);
    }
  },
  methods: {
    createGraphData() {
      this.result = this.lookerGrapherData.data;
      let model = [];
      let modelMarker = 0;
      for (let [key, value] of Object.entries(this.result)) {
        if (Object.keys(value).length && key) {
          for (let [modelKey, modelValue] of Object.entries(value)) {
            model[modelMarker] = { key: modelKey, value: modelValue };
            modelMarker++;
          }
        }
      }

      let explore = [];
      let exploreMarker = 0;
      for (let j = 0; j < model.length; j++) {
        if (model[j].key !== 'orphan' && this.addNode(`${model[j].key}.model`, model[j].key, 4, this.childCount[3])) {
          this.childCount[3] = ++this.childCount[3];
          this.maxLevel = this.maxLevel > this.childCount[3] ? this.maxLevel : this.childCount[3];
        }
        for (let [exploreKey, exploreValue] of Object.entries(model[j].value)) {
          explore[exploreMarker] = { key: exploreKey, value: exploreValue };
          exploreMarker++;
          if (exploreKey === 'orphan') {
            continue;
          }
          this.addLinks(`${model[j].key}.model`, `${exploreKey}.explore`);
          if (this.addNode(`${exploreKey}.explore`, exploreKey, 3, this.childCount[2])) {
            this.childCount[2] = ++this.childCount[2];
            this.maxLevel = this.maxLevel > this.childCount[2] ? this.maxLevel : this.childCount[2];
          }
        }
      }
      for (let i = 0; i < explore.length; i++) {
        for (let j = 0; j < explore[i].value.length; j++) {
          if (this.addNode(`${explore[i].value[j].view_name}.view`, explore[i].value[j].view_name, 2, this.childCount[1])) {
            this.childCount[1] = ++this.childCount[1];
            this.maxLevel = this.maxLevel > this.childCount[1] ? this.maxLevel : this.childCount[1];
          }
          if (explore[i].key !== 'orphan') {
            this.addLinks(`${explore[i].key}.explore`, `${explore[i].value[j].view_name}.view`);
          }
          if (this.addNode(`${explore[i].value[j].table_name}.table`, explore[i].value[j].table_name, 1, this.childCount[0])) {
            this.childCount[0] = ++this.childCount[0];
            this.maxLevel = this.maxLevel > this.childCount[0] ? this.maxLevel : this.childCount[0];
          }
          this.addLinks(`${explore[i].value[j].view_name}.view`, `${explore[i].value[j].table_name}.table`);
        }
      }
    },

    errorMessage(e) {
      console.log(e);
      if (e.status === 404) {
        this.error = "Couldn't detect any Looker Dependencies for this table.";
      } else if (e.status === 500) {
        this.error = `${e.data.Message} Please try refreshing to see if this fixes this temporary issue.`;
      } else {
        this.error = e;
      }
    },
    addNode(id, name, group, level) {
      let keys = this.graphData.nodes.map(a => a.id);
      if (keys.indexOf(id) === -1) {
        this.graphData.nodes.push({ id: id, name: name, group: group, level: level, x: (group) * 200, y: level });
        return true;
      }
      return false;
    },
    addLinks(source, target) {
      let keys = this.graphData.links.map(a => `${a.source}.${a.target}`);
      if (keys.indexOf(`${source}.${target}`) < 0 && keys.indexOf(`${target}.${source}`) < 0) {
        this.graphData.links.push({ source: source, target: target });
      }
    },
    mountGraph() {
      const height = (this.maxLevel) * 50;
      const width = 900 + this.maxLevel * 10;
      const data = this.graphData;
      const rectWidth = 140;
      const rectHeight = 22;

      function color(group) {
        if (group === 1) {return 'rgb(214, 39, 40)';} // For tables -> Color: Red
        if (group === 2) {return 'rgb(44, 160, 44)';} // For Views -> Color: Green
        if (group === 3) {return 'rgb(255, 127, 14)';} // For Explores -> Color: Orange
        if (group === 4) {return 'rgb(31, 119, 180)';} // For Models -> Color: Blue
        return '#000000';
      }

      function chart() {
        const links = data.links.map(d => Object.create(d));
        const nodes = data.nodes.map(d => Object.create(d));
        // To remove cached looker graph before loading the new (fresh) one
        const oldSvg = d3.select('#lookerTree');
        oldSvg.selectAll('*').remove();
        const svg = d3
          .select('#lookerTree')
          .append('svg')
          .attr('viewBox', [0, 0, width, height]);

        d3.forceSimulation(nodes)
          .force('charge', d3.forceManyBody().strength(-8))
          .force('center', d3.forceCenter(width / 2, height / 2))
          .force('collide', d3.forceCollide().radius(10))
          .on('tick', ticked);

        svg.append('g').attr('class', 'links');
        svg.append('g').attr('class', 'nodes');
        let t = svg.select('.links')
          .selectAll('line')
          .data(links);

        let line = t.enter()
          .append('line')
          .merge(t)
          .attr('stroke', 'black')
          .attr('opacity', 0.4);

        let u = svg.select('.nodes')
          .selectAll('rect')
          .data(nodes);

        let rect = u.enter()
          .append('rect')
          .merge(u)
          .attr('width', rectWidth)
          .attr('height', rectHeight)
          .attr('fill', d => color(d.group))
          .on('mouseover', fade(0.1))
          .on('mouseout', fade(1));

        let text = u.enter()
          .append('text')
          .attr('fill', 'white')
          .text(d => `${d.name.length > 20 ? `${d.name.substr(0, 20)}...` : d.name}`)
          .style('font-size', '0.7em')
          .style('cursor', 'default')
          .on('mouseover', fade(0.1))
          .on('mouseout', fade(1));

        function ticked() {
          linkCoordinated();
          line
            .attr('x1', d => d.sourceX)
            .attr('y1', d => d.sourceY + rectHeight / 2)
            .attr('x2', d => d.targetX + rectWidth)
            .attr('y2', d => d.targetY + rectHeight / 2);

          rect
            .attr('x', d => d.x)
            .attr('y', d => d.y);

          text.attr('x', d => d.x + 5)
            .attr('y', d => d.y + 12);
        }

        function linkCoordinated() {
          for (let i = 0; i < links.length; i++) {
            for (let j = 0; j < nodes.length; j++) {
              if (links[i].source === nodes[j].id) {
                links[i].sourceX = nodes[j].x;
                links[i].sourceY = nodes[j].y;
              }
              if (links[i].target === nodes[j].id) {
                links[i].targetX = nodes[j].x;
                links[i].targetY = nodes[j].y;
              }
            }
          }
        }
        d3.select('body').append('div')
          .attr('class', 'nodeTooltip')
          .style('opacity', 0);

        let div = d3.select('.nodeTooltip');

        function getAllIndexes(arr, val) {
          let indexes = []; let i = -1;
          while ((i = arr.indexOf(val, i + 1)) !== -1) {
            indexes.push(i);
          }
          return indexes;
        }
        let connections = [];

        function getLeftConnections(focusedNode) {
          let source = links.map(d => d.source);
          let occurance = getAllIndexes(source, focusedNode);

          if (occurance.length) {
            if (occurance.length > 1) {
              for (let i = 0; i < occurance.length; i++) {getLeftConnections(links[occurance[i]].target);}
            } else {
              getLeftConnections(links[occurance[0]].target);
            }
          }
          if (!connections.includes(focusedNode)) {connections.push(focusedNode);}
          return focusedNode;
        }
        function getRightConnections(focusedNode) {
          let source = links.map(d => d.target);
          let occurance = getAllIndexes(source, focusedNode);

          if (occurance.length) {
            if (occurance.length > 1) {
              for (let i = 0; i < occurance.length; i++) {getRightConnections(links[occurance[i]].source);}
            } else {
              getRightConnections(links[occurance[0]].source);
            }
          }
          if (!connections.includes(focusedNode)) {connections.push(focusedNode);}
          return focusedNode;
        }
        let type = ['Table', 'Looker View', 'Explore', 'Model'];

        function fade(opacity) {
          return function(d) {
          // check all other nodes to see if they're connected
          // to this one. if so, keep the opacity at 1, otherwise
          // fade
            if (opacity < 1) {
              connections = [];
              getLeftConnections(d.id);
              getRightConnections(d.id);
              div.transition()
                .duration(200)
                .style('opacity', 0.9);
              div.html(`${type[d.group - 1]}<br/>${d.name}`)
                .style('left', `${d3.event.pageX - 30}px`)
                .style('top', `${d3.event.pageY - 50}px`);
            } else {
              div.transition()
                .duration(500)
                .style('opacity', 0);
            }
            rect.style('stroke-opacity', o => {
              if (connections.includes(o.id)) {return 1;}
              return opacity;
            });
            rect.style('fill-opacity', o => {
              if (connections.includes(o.id)) {return 1;}
              return opacity;
            });
            // also style link accordingly
            line.style('stroke-opacity', o => {
              if (connections.includes(o.source) && connections.includes(o.target)) {
                return 1;
              }
              return opacity;
            });
          };
        }
      }
      chart();
    }
  }
};
</script>

<style scoped>
.legend{
  position: absolute;
}
</style>
