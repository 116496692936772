<template>
  <!-- Welcome Message -->
  <div align="center">
    <h2 class="dark-orange">
      Welcome to Data Discovery
    </h2>
    <h5 class="dark-orange hidden-sm hidden-xs">
      Unlock the hidden value of the data
    </h5>
    <br />
    <span class="help-block hidden-sm hidden-xs">
      <p>
        A web-based platform for data cataloging and discovery developed for
        Cimpress teams and businesses.
      </p>
      <p>
        Data Discovery allows to find data providers and their documentation, as
        well as to curate business context for the consumers of your data.
      </p>
      <p>
        Need Help? Just slack
        <a
          href="https://cimpress.slack.com/app_redirect?channel=data-domain-support"
          target="_blank"
        >#data-domain-support</a>
        or email
        <a href="mailto:PandaSquad@cimpress.com">PandaSquad@cimpress.com</a>
      </p>
    </span>
  </div>
</template>
<script>
export default {
  name: 'DataDiscoveryHeader'
};
</script>
