<template>
  <div>
    <h4>Popular tags</h4>

    <div v-if="loadingTags">
      Loading tags...
    </div>

    <div v-else-if="tagsError">
      Error loading the tags.
    </div>

    <div v-else-if="!popularTags || !popularTags.length">
      There are no popular tags.
    </div>

    <div v-else>
      <a
        v-for="tag in popularTags"
        :key="tag.tag_name"
        class="customlink"
        @click="() => handleClick(tag.tag_name)"
      >
        <div class="inline tag text-tile">
          {{ tag.tag_name }} ({{ tag.tag_count }})
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'PopularTagsSection',
  inject: ['platformClient'],
  computed: {
    ...mapState({
      popularTags(state) {
        return [...((state.tags.allTags || []).tag_usages || [])]
          .sort((a, b) => b.tag_count - a.tag_count)
          .slice(0, 10);
      },
      loadingTags: state => state.tags.loadingTags,
      tagsError: state => state.tags.tagsError
    })
  },
  methods: {
    handleClick(tag) {
      this.$emit('onTagClick', {
        tag: tag
      });
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.text-tile {
  color: #0088a9;
  font-size: 0.9em;
  margin: 3px 8px 0px 0px;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.text-tile:hover {
  color: #fff;
  background-color: #0088a9;
}
</style>
