const normalizeSearchInput = searchInput => {
  const filteredSearchInput = {};
  if (searchInput.searchText) {
    filteredSearchInput.searchText = searchInput.searchText;
  }
  const filters = _.omitBy(searchInput.filters, _.isEmpty);
  if (!_.isEmpty(filters, true)) {
    filteredSearchInput.filters = filters;
  }
  return filteredSearchInput;
};

export default function urlEncodeSearchInput(searchInput) {
  const normalizedSearchInput = normalizeSearchInput(searchInput);
  return JSON.stringify(normalizedSearchInput);
}
