<template>
  <div>
    <h4>Other Resources</h4>
    <span
      v-for="key in Object.keys(documentationLinks)"
      :key="key"
      class="label label-primary"
      style="margin: 5px 10px 5px 0px"
    >
      <a
        :href="documentationLinks[key][0]"
        style="color: white"
        target="_blank"
      >
        <i class="fa" :class="documentationLinks[key][1]" />
        {{ key }}
      </a>
    </span>
  </div>
</template>
<script>
export default {
  name: 'OtherResources',
  data() {
    return {
      documentationLinks: {
        'Introduction to Data Discovery': [
          'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/518750596/Data+Discovery',
          'fa-map-o'
        ],
        'Creating Stable Data Sets': [
          'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/1257179058/Creating+Stable+Data+Sets',
          'fa-book'
        ],
        'Metadata API Documentation': [
          'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/2055307610/Using+Data+Discovery+API+to+update+attributes+of+stable+data+sets',
          'fa-file-text'
        ],
        'Search Service API': [
          'https://developer.cimpress.io/apis/business-analytics/data-discovery-search-service',
          'fa-file-code-o'
        ],
        'Metadata API': [
          'https://developer.cimpress.io/apis/business-analytics/data-discovery-metadata-service',
          'fa-file-code-o'
        ]
      }
    };
  }
};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
