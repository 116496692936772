export const clusterToSlackWorkspace = {
  'cimpress.eu-west-1': 'https://cimpress.slack.com',
  'printdeal.eu-west-1': 'https://cimpress.slack.com',
  'cimpressvcs.eu-west-1': 'https://cimpress.slack.com',
  'vistaprint.eu-west-1': 'https://vistaprint.slack.com',
  'nationalpen.eu-west-1': 'https://cimpress.slack.com',
  'tradeprint.eu-west-1': 'https://cimpress.slack.com',
  'exagroup.eu-west-1': 'https://cimpress.slack.com',
  'druck.eu-west-1': 'https://cimpress.slack.com',
  'buildasign.eu-west-1': 'https://cimpress.slack.com',
  'pixartprinting.eu-west-1': 'https://cimpress.slack.com',
  'wmd.eu-west-1': 'https://cimpress.slack.com',
  'boxup.eu-west-1': 'https://cimpress.slack.com',
  'printi.eu-west-1': 'https://cimpress.slack.com'
};
