<template>
  <div class="inline-block">
    <div v-if="dropdownColumns.length !== 0">
      <span class="pull-right customlink" @click="HistoryOn=true">
        <Tooltip direction="top" contents="History" class="inline">
          <IconSynchronizeArrowClock size="lg" />
        </Tooltip>
      </span>
      <EditHistoryTable v-if="HistoryOn" :entityId="promiseOfFreshnessEntity" :entityType="'Promise Of Freshness'" @closeModal="HistoryOn = false" />
    </div>
    <div>
      <p class="pull-left">
        Promise of Freshness
      </p>
      <Tooltip
        direction="top"
        :contents="`Promise of Freshness is an attribute which refers to the frequency of data update on this ${objectType}. Only stewards are allowed to edit.`"
        class="inline pull-left"
        style="padding-left: 1rem"
      >
        <IconInformationCircle size="md" weight="fill" class="text-primary" />
      </Tooltip>
      <div v-if="dropdownColumns.length !== 0 && isUserSteward">
        <button v-if="!isEditing && !this.isObjectReadOnly" class="btn-icon transparent" style="display: box; padding-right: 11px; none;" @click="isEditing=true">
          <Tooltip direction="top" contents="Edit">
            <i class="fa fa-pencil text-primary" style="padding: 0;" />
          </Tooltip>
        </button>
        <div v-if="isEditing"
             ref="btnPreviewData"
             class="tag tag_modified inline pull-right"
             style="cursor: pointer;"
             :disabled="isDisabled()"
             v-on:click="processResetButtonClick()"
        >
          Reset
        </div>
        <div v-if="!isEditing && isLoading" class="loading inline pull-right" align="center">
          <img src="spinner.svg" width="25" alt="loading">
        </div>
      </div>
      <br />
      <hr style="width: 100%;">
    </div>
    <ConfirmBox
      v-if="showWarning"
      :title="`Remove Promise of Freshness`"
      :isWarning=true
      :message="`Your dataset <b>won't be stable</b> anymore. Are you  sure you want to remove <b>Promise of Freshness</b>?`"
      @confirmOperation="
        () => {
          resetPromiseOfFreshness();
          this.$emit('status:downgrade');
        }
      "
      @closeModal="showWarning = false"
    />
    <div v-if="dropdownColumns.length === 0">
      <span>
        Promise of Freshness cannot be defined as no <b>timestamp</b> type column found. Please add a column of the required type to use the feature.
      </span>
      <a href="https://cimpress-support.atlassian.net/wiki/spaces/DPT/pages/1257179058/Attributes+of+a+Stable+Data+Set" target="_blank">
        <Tooltip direction="top" contents="To read more details, click here!" class="inline">
          <IconInformationCircle size="md" weight="fill" class="text-primary" />
        </Tooltip>
      </a>
    </div>
    <div v-if="!isEditing && dropdownColumns.length !== 0">
      <a v-if="promiseOfFreshness === '' && isUserSteward && !this.isObjectReadOnly" class="customlink" @click="isEditing=true">+ Add Promise of Freshness</a>
      <!-- eslint-disable -->
      <div style="word-wrap: break-word;" v-html="promiseOfFreshness" />
      <!-- eslint-enable -->
    </div>
    <span v-if="(!isUserSteward || this.isObjectReadOnly) && promiseOfFreshness === '' && dropdownColumns.length !== 0" class="text-muted">
      Promise of Freshness is not defined
    </span>
    <div v-if="isEditing && dropdownColumns.length !== 0 && isUserSteward" class="row card-block">
      <div class="col-md-3" style="padding-left: 0; padding-right: 5px;">
        <TextField
          label="Enter Latency"
          type="number"
          min="1"
          max="30"
          :value="duration"
          :onChange="onDurationInput"
          :helpText="userInputError ? `Latency is out of range` : ''"
          :bsStyle="userInputError ? `error` : ''"
          required
        />
      </div>
      <div
        v-click-outside="closeDurationFilterDropdown"
        class="searchMethod col-md-4"
        required
        @click="selectDurationFilterClick = !selectDurationFilterClick"
      >
        <a class="dropdown-toggle customlink">{{ selectedDurationFilter }}<span style="float: right" class="caret" /></a>
        <div class="dropdown" :class="selectDurationFilterClick ? 'open' : 'close'">
          <ul class="dropdown-menu">
            <li>
              <a title="Duration in number of months" @click="selectedDurationFilter = 'Months'">Months</a>
              <a title="Duration in number of days" @click="selectedDurationFilter = 'Days'">Days</a>
              <a title="Duration in number of hours" @click="selectedDurationFilter = 'Hours'">Hours</a>
              <a title="Duration in number of minutes" @click="selectedDurationFilter = 'Minutes'">Minutes</a>
            </li>
          </ul>
        </div>
      </div>
      <div v-click-outside="closeReferenceColumnDropdown"
           class="col-md-5"
           style="padding-left: 5px; padding-right: 0px;"
           required
           @click="selectReferenceColumnClick = !selectReferenceColumnClick"
      >
        <div class="searchMethod">
          <a class="dropdown-toggle customlink">
            {{ selectedReferenceColumn.length > 22 ? selectedReferenceColumn.substr(0, 22) + '...': selectedReferenceColumn }}
            <span style="float: right" class="caret" />
          </a>
          <Tooltip
            direction="top"
            contents="Reference Column is the column that indicates the timestamp of the Data object update.
          The latest timestamp in the reference column is used to evaluate if this data meets latency expectations"
            class="inline"
          >
            <IconInformationCircle size="md" weight="fill" class="text-primary" />
          </Tooltip>
          <div class="dropdown" :class="selectReferenceColumnClick ? 'open' : 'close'">
            <ul class="dropdown-menu">
              <li v-for="column in dropdownColumns" :key="column" align="center">
                <a @click="selectedReferenceColumn = column">{{ column }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEditing" class="pull-right">
      <Button
        v-if="!isLoading"
        ref="btnPreviewData"
        class="inline"
        variant="default"
        size="md"
        @onClick="isEditing=false;"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        class="inline"
        style="margin: 0;"
        size="md"
        :disabled="isDisabled()"
        @onClick="updatePromiseOfFreshness"
      >
        <span v-if="!isLoading">Update</span>
        <img v-else src="spinner.svg" width="15" alt="loading">
      </Button>
    </div>
    <error-popup v-if="isEditing && errorMessage != null" errorType="alert-danger" :message="errorMessage" style="padding: 10px 0px 10px 0px;" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Tooltip, TextField, Button } from '@cimpress/react-components';
import { IconSynchronizeArrowClock, IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import EditHistoryTable from './../../EditHistoryTable';
import vClickOutside from 'v-click-outside';
import ErrorPopup from '../../ErrorPopup.vue';
import ConfirmBox from '../../ConfirmBox';

export default {
  name: 'PromiseOfFreshness',
  inject: ['userManager', 'metadataPlatformClient'],
  components: {
    ConfirmBox,
    TextField,
    IconSynchronizeArrowClock,
    IconInformationCircle,
    Tooltip,
    Button,
    EditHistoryTable,
    ErrorPopup
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    keyValue: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      selectReferenceColumnClick: false,
      selectedReferenceColumn: 'Select Reference Column',
      selectDurationFilterClick: false,
      selectedDurationFilter: 'Hours',
      duration: 1,
      userInputError: false,
      isEditing: false,
      promiseOfFreshness: '',
      promiseOfFreshnessEntity: '',
      HistoryOn: false,
      isLoading: false,
      dropdownColumns: [],
      errorMessage: null,
      objectType: null,
      showWarning: false
    };
  },
  computed: {
    ...mapState({
      tableInfo: state => state.tables.tableInfo,
      missingStabilityCriteria: state => state.tables.missingStabilityCriteria,
      customFields: state => state.tables.customFields,
      isObjectReadOnly: state => state.tables.isObjectReadOnly
    }),
    isUserSteward() {
      const userEmail = this.userManager.userData.app_metadata.canonical_id.toLowerCase();
      return !!this.tableInfo.owners.find(owner => owner.email.toLowerCase() === userEmail);
    }
  },
  watch: {
    duration(duration) {
      this.duration = parseInt(duration);
      this.validateDuration();
    },
    selectedDurationFilter() {
      this.validateDuration();
    },
    isUserSteward() {
      if (this.isUserSteward === false) {
        this.isEditing = false;
      }
    }
  },
  created() {
    if (this.tableInfo) {
      this.objectType = this.tableInfo.is_view ? 'View' : 'Table';
    }
    this.promiseOfFreshnessEntity = `${this.keyValue}/promise_of_freshness`;
    this.filterReferenceColumn();
    this.setInputFields();
  },
  methods: {
    ...mapActions({
      putTablePromiseOfFreshness: 'tables/putTablePromiseOfFreshness'
    }),
    processResetButtonClick() {
      if (this.customFields.table_status === 'stable' && !this.missingStabilityCriteria.length) {
        this.showWarning = true;
      } else {
        this.resetPromiseOfFreshness();
      }
    },
    filterReferenceColumn() {
      const allowedDataTypes = ['date', 'datetime', 'time', 'timestamp', 'timestamp_ltz', 'timestamp_ntz', 'timestamp_tz'];
      for (let column of this.tableInfo.columns) {
        if (allowedDataTypes.indexOf(column.col_type) >= 0) {
          this.dropdownColumns.push(column.name);
        }
      }
    },
    onDurationInput(event) {
      this.duration = event.target.value;
    },
    closeDurationFilterDropdown() {
      this.selectDurationFilterClick = false;
    },
    closeReferenceColumnDropdown() {
      this.selectReferenceColumnClick = false;
    },
    setInputFields() {
      if (this.customFields.pof_duration !== 0 && this.customFields.pof_duration !== null) {
        this.duration = this.customFields.pof_duration;
        this.selectedDurationFilter = this.customFields.pof_duration_filter;
        this.selectedReferenceColumn = this.customFields.pof_reference_column;
        this.promiseOfFreshness = `${this.objectType} content reflects the promise of freshness of <b>${this.duration} ${this.selectedDurationFilter}</b>. The last update time of the ${this.objectType} can be identified using <b>${this.selectedReferenceColumn}</b> column.`;
      }
    },
    isDisabled() {
      return this.userInputError === true || this.selectedReferenceColumn === 'Select Reference Column';
    },
    validateDuration() {
      if (this.selectedDurationFilter === 'Months' && (this.duration > 12 || this.duration < 1 || isNaN(this.duration))) {
        this.userInputError = true;
      } else if (this.selectedDurationFilter === 'Days' && (this.duration > 30 || this.duration < 1)) {
        this.userInputError = true;
      } else if (this.selectedDurationFilter === 'Hours' && (this.duration > 24 || this.duration < 1)) {
        this.userInputError = true;
      } else if (this.selectedDurationFilter === 'Minutes' && (this.duration > 60 || this.duration < 1)) {
        this.userInputError = true;
      } else {
        this.userInputError = false;
      }
    },
    async updatePromiseOfFreshness() {
      this.isLoading = true;
      this.errorMessage = null;
      try {
        await this.putTablePromiseOfFreshness({
          platformClient: this.metadataPlatformClient,
          keyValue: this.keyValue,
          pofDuration: this.duration,
          pofDurationFilter: this.selectedDurationFilter,
          pofReferenceColumn: this.selectedReferenceColumn
        });
        this.promiseOfFreshness = `${this.objectType} content reflects the promise of freshness of <b>${this.duration} ${this.selectedDurationFilter}</b>. The last update time of the ${this.objectType} can be identified using <b>${this.selectedReferenceColumn}</b> column.`;
        this.isEditing = false;
      } catch (e) {
        this.errorMessage = e.message || e.data.message || e.data.title || (e.status && `Error occurred with status code ${e.status}`) || 'Unknown error occurred';
        console.log(e);
      }
      this.isLoading = false;
    },
    async resetPromiseOfFreshness() {
      this.showWarning = false;
      this.isLoading = true;
      this.errorMessage = null;
      try {
        await this.putTablePromiseOfFreshness({
          platformClient: this.metadataPlatformClient,
          keyValue: this.keyValue,
          pofDuration: 0,
          pofDurationFilter: '',
          pofReferenceColumn: ''
        });
        this.duration = 1;
        this.selectedDurationFilter = 'Hours';
        this.selectedReferenceColumn = 'Select Reference Column';
        this.promiseOfFreshness = '';
      } catch (e) {
        this.errorMessage = e.message || e.data.message || e.data.title || (e.status && `Error occurred with status code ${e.status}`) || 'Unknown error occurred';
        console.log(e);
      }
      this.isLoading = false;
    }
  }
};
</script>
<style scoped>
a {
  text-decoration: none;
}
p {
  color: #00738e;
  font-size: 1.5em;
  margin-bottom: 0px;
}
.btn {
  padding: 7px 14px;
  border: none !important;
  text-decoration: none;
}

</style>
